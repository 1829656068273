import { useState } from "react";
import { useStyles } from "./styles";
import { validateTags } from "../../../../components/helpers/validate";
import noImageLogo from "../../../../assets/images/no-image.jpg";
import IconComponent from "../../../../components/fields/icon";
import CategoriesDropDown from "../../../../components/categoriesDropDown";
import { ReactComponent as LeftArrow } from "../../../../assets/svg/arrowLeft.svg";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import ImageUpload from "./ImageUpload";

export default function MobileProductDetails(props) {
  const {
    selectedItems,
    setSelectedItems,
    handleSelectItem,
    inputErrors,
    setInputErrors,
    categoriesList,
    activeIndex,
    setActiveIndex,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [imageSelected, setImageSelected] = useState(selectedItems);
  const [selectedOptions, setSelectedOptions] = useState(selectedItems[activeIndex]?.occasions ? selectedItems[activeIndex]?.occasions.split(", "): []);
  const options = [
    { value: "Business Casual", label: "Business Casual" },
    { value: "Casual Wear", label: "Casual Wear" },
    { value: "Date Night", label: "Date Night" },
    { value: "Formal Wear", label: "Formal Wear" },
    { value: "Summer Time", label: "Summer Time" },
    { value: "Party Wear", label: "Party Wear" },
  ];

  function handleClick(event) {
    return setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMenuClick(e) {
    selectedItems[activeIndex]["subCategory"] = e;
    selectedItems[activeIndex]["isCategoryModified"] = true;
    setSelectedItems([...selectedItems]);
    handleClose();

    if (inputErrors[activeIndex]["category"]) {
      inputErrors[activeIndex]["category"] = false;
      setInputErrors(JSON.parse(JSON.stringify(inputErrors)));
    }
  }

  function handleValueChange(value, type) {
    if (type === "size") {
      if (value.length > 7) {
        return;
      }
      value = value.toUpperCase();
    } else if (type === "tags") {
      if (
        (value.length === 1 && value === " ") ||
        (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
      ) {
        return;
      } else if (value.length === 1 && value !== "#") {
        value = "#" + value;
      } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
        value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
      }

      //handle when user paste the tags
      if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
        value = validateTags(value.trim());
      }
    }

    selectedItems[activeIndex][type] = value;
    setSelectedItems([...selectedItems]);

    if (inputErrors[activeIndex][type]) {
      inputErrors[activeIndex][type] = false;
      setInputErrors(JSON.parse(JSON.stringify(inputErrors)));
    }
  }

  function handlePrevClick(event) {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
    event.stopPropagation();
  }

  function handleNextClick(event) {
    if (activeIndex < selectedItems.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
    event.stopPropagation();
  }

  function handleClickRemove() {
    let index = selectedItems[activeIndex].index;
    handleSelectItem(selectedItems[activeIndex], index - 1);
  }

  const handleImageSelected = (selectedImage, itemIndex, isSrc = false) => {
    const imagesList = selectedItems;
    imagesList[itemIndex].src = isSrc ? selectedImage : `${URL.createObjectURL(selectedImage)}`;
    localStorage.setItem("imagesList", imagesList);
    setImageSelected(imagesList);
    handleValueChange(selectedImage, "src");
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value],
    );
    let changedSelectedOptions = selectedOptions.includes(value)
    ? selectedOptions.filter((option) => option !== value)
    : [...selectedOptions, value]
    handleValueChange(changedSelectedOptions.join(", "), "occasions")
  };

  return (
    <div className={classes.mDetails}>
      <div className={classes.removeButton}>
        <IconComponent
          className={classes.removeIcon}
          iconTitle={"CrossIcon"}
          onClick={handleClickRemove}
        />
      </div>
      <div className={classes.buttonControl}>
        {selectedItems.length > 1 && (
          <div
            className={`${classes.navigationButton} ${
              activeIndex > 0 ? classes.active : classes.deactive
            }`}
            onClick={(e) => handlePrevClick(e)}
          >
            <LeftArrow />
          </div>
        )}
        <div
          className={`${classes.mImgContainer} ${
            selectedItems.length > 1 ? classes.imageContainer1 : classes.imageContainer2
          }`}
        >
          <img
            className={classes.mImage}
            src={
              imageSelected[activeIndex]?.src?.name
                ? URL.createObjectURL(imageSelected[activeIndex]?.src)
                : selectedItems[activeIndex]?.src
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = noImageLogo;
            }}
          />
          <ImageUpload
            className={classes.mImage}
            handleImageSelected={handleImageSelected}
            selectedItems={selectedItems}
            activeIndex={activeIndex}
          />
        </div>

        {selectedItems.length > 1 && (
          <div
            className={`${classes.navigationButton} ${classes.nextButton} ${
              activeIndex < selectedItems.length - 1 ? classes.active : classes.deactive
            }`}
            onClick={(e) => handleNextClick(e)}
          >
            <LeftArrow />
          </div>
        )}
      </div>
      <div className={classes.inputLabel}>{"Item Name: "}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"item"}
          value={selectedItems[activeIndex].itemName ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "itemName")}
        />
      </div>
      <div className={classes.inputLabel}>
        {"Category: "}
        <span>{"*"}</span>
      </div>
      <div
        id={"category"}
        className={`${classes.selectInputWrapper} ${
          inputErrors[activeIndex].category ? classes.errorInputWrapper : classes.validInputWrapper
        }`}
      >
        <CategoriesDropDown
          selectedCategory={selectedItems[activeIndex].subCategory || selectedItems[activeIndex].category || ""}
          handleOpen={handleClick}
          handleClose={handleClose}
          open={open}
          categoriesList={categoriesList}
          handleMenuClick={handleMenuClick}
        />
      </div>

      {/* from here */}
      <div className={classes.inputLabel}>{"Style Type: (Multi-Select) "}<span>{"*"}</span></div>
        <div className="multi-select-options">
          <div
            style={{
              height: "auto",
              marginBottom: "0px",
              padding: selectedOptions.length ? "18px 8px" : "0px",
            }}
            className={
              "selected-options " +
              `${classes.inputWrapper} ${classes.validInputWrapper}`
            }
          >
            {selectedOptions.length <= 2 ? (
              selectedOptions.map((option) => (
                <span key={option} className="selected-option">
                  {options.find((opt) => opt.value === option).label}
                </span>
              ))
            ) : (
              <>
                {selectedOptions.slice(0, 2).map((option) => (
                  <span key={option} className="selected-option">
                    {options.find((opt) => opt.value === option).label}
                  </span>
                ))}
                <span className="more-options">..+ {selectedOptions.length - 2}</span>
              </>
            )}
          </div>
          <div className="multi-select-option-group">
            {options.map((option) => (
              <div key={option.value} className="multi-select-option">
                <input
                  type="checkbox"
                  id={option.value}
                  value={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={handleChange}
                  className="custom-checkbox"
                />
                <label htmlFor={option.value}>{option.label}</label>
              </div>
            ))}
          </div>
        </div>

        {/* style-type */}


      <div className={classes.infoWrapper}>
        <div className={classes.inputLabel}>{"Size:"}</div>
        <a
          data-tooltip-id="sizeInfo-tooltip"
          data-tooltip-content='If size is not available please enter "NA"'
        >
          <IconComponent iconTitle={"InfoIcon"} />
        </a>
      </div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"size"}
          value={selectedItems[activeIndex].size ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "size")}
        />
      </div>
      <div className={classes.inputLabel}>{"Brand Name:"}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"brand"}
          value={selectedItems[activeIndex].brand ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "brand")}
        />
      </div>
      <div className={classes.inputLabel}>{"Store Name:"}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"store"}
          value={selectedItems[activeIndex].store ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "store")}
        />
      </div>

      <div className={classes.inputLabel}>{"Color:"}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"color"}
          value={selectedItems[activeIndex].color ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "color")}
        />
      </div>

      <div className={classes.inputLabel}>{"Price:"}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"price"}
          value={selectedItems[activeIndex].price ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "price")}
        />
      </div>

      <div className={classes.inputLabel}>{"Date purchased:"}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        {/* <input
          className={classes.textInput}
          type="text"
          id={"datePurchased"}
          value={selectedItems[activeIndex].datePurchased ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "datePurchased")}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            className={classes.datePicker}
            inputFormat="MM/DD/YYYY"
            value={selectedItems[activeIndex].datePurchased}
            onChange={(value) => handleValueChange(value, "datePurchased")}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <div className={classes.inputLabel}>{"Link to Item:"}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"linkToItem"}
          value={selectedItems[activeIndex].productLink ?? ""}
          onChange={(e) => handleValueChange(e.target.value, "productLink")}
        />
      </div>

      <div className={classes.inputLabel}>{"Tags:"}</div>
      <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
        <input
          className={classes.textInput}
          type="text"
          id={"tags"}
          value={selectedItems[activeIndex].tags ?? ""}
          placeholder="#tag_name1 #tag_name2"
          onChange={(e) => handleValueChange(e.target.value, "tags")}
        />
      </div>
    </div>
  );
}
