export default {
  colors: {
    white: "#FFFFFF",
    black: { regular: "#000000", light: "#323333", lighter: "#525A68" },
    orange: { regular: "#DC5305", light: "#FB5B01" },
    grey: { regular: "#CACACA", light: "#F9F9f9", dark: "#B9B9B9", darker: "#AEAEAE" },
    green: { regular: "#2EBB55", light: "#2DB955" },
    red: { regular: "#FF0000", light: "#FFCCCC" },
    blue: { light: "#EAF5FF" },
  },
  mediaQueries: {
    smallMobileScreen: "max-width: 375px",
    smallMobile: "max-width: 350px",
    mobile: "max-width: 450px",
    smallTablet: "max-width: 600px",
    largerMobile: "max-width: 500px",
    largerThanMobile: "min-width: 451px",
    tablet: "max-width: 1024px",
    largerThanTablet: "min-width: 1100px",
    largeTablet: "max-width: 1100px",
    mediumScreen: "max-width: 1440px",
    ipadScreen: "max-width: 1024px",
    smallIpadScreen: "max-width: 767px",
  },
};
