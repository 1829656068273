import { useState, useEffect } from "react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import ImageUpload from "./ImageUpload";
import { useStyles } from "./styles";
import "./styles.css";
import CategoriesDropDown from "../../../../components/categoriesDropDown";
import noImageLogo from "../../../../assets/images/no-image.jpg";
import IconComponent from "../../../../components/fields/icon";
import { validatePrice, validateTags } from "../../../../components/helpers/validate";

export default function ProductDetails(props) {
  const {
    item,
    index,
    selectedItems,
    setSelectedItems,
    handleSelectItem,
    inputErrors,
    setInputErrors,
    categoriesList,
  } = props;
  const options = [
    { value: "Business Casual", label: "Business Casual" },
    { value: "Casual Wear", label: "Casual Wear" },
    { value: "Date Night", label: "Date Night" },
    { value: "Formal Wear", label: "Formal Wear" },
    { value: "Summer Time", label: "Summer Time" },
    { value: "Party Wear", label: "Party Wear" },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(selectedItems[index]?.occasions ? selectedItems[index]?.occasions.split(", ") : []);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  function handleClick(event) {
    return setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMenuClick(e) {
    selectedItems[index] = { ...item, subCategory: e, isCategoryModified: true };
    setSelectedItems([...selectedItems]);

    if (inputErrors[index]["category"]) {
      inputErrors[index]["category"] = false;
      setInputErrors(JSON.parse(JSON.stringify(inputErrors)));
    }
    handleClose();
  }

  function handleValueChange(value, type) {
    if (type === "size") {
      if (value.length > 7) {
        return;
      }
      value = value.toUpperCase();
    } else if (type === "tags") {
      if (
        (value.length === 1 && value === " ") ||
        (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
      ) {
        return;
      } else if (value.length === 1 && value !== "#") {
        value = "#" + value;
      } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
        value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
      }
      // to handle when user paste the tags
      if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
        value = validateTags(value.trim());
      }
    } else if (type === "price") {
      if (value.length === 1 && value === ".") {
        value = "$0" + value;
      } else if (value.length === 1 && value.match(/\d/g)) {
        value = "$" + value;
      } else if (value !== "" && !validatePrice(value)) {
        return;
      }
    }

    selectedItems[index][type] = value;
    setSelectedItems(structuredClone(selectedItems));
    if (inputErrors[index][type]) {
      inputErrors[index][type] = false;
      setInputErrors(JSON.parse(JSON.stringify(inputErrors)));
    }
  }
  const handleImageSelected = (selectedImage) => {
    handleValueChange(selectedImage.fileImage, "src");
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((opt) => opt !== value)  // Deselect
      : [...selectedOptions, value];  // Select

    setSelectedOptions(newSelectedOptions);

    // Update selectedItems with new occasions
    const updatedItems = [...selectedItems];
    updatedItems[index] = {
      ...updatedItems[index],
      occasions: newSelectedOptions.join(", "),  // Store as a comma-separated string
    };
    setSelectedItems(updatedItems);  // Update the parent state
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (isOpen && !event.target.closest(".multi-select-dropdown")) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  const [marginTop, setMarginTop] = useState("140px");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width >= 1024 && width < 1440) {
        setMarginTop("190px"); // Adjust this value as needed
      } else if (width >= 768 && width < 1024) {
        setMarginTop("135px"); // Adjust this value as needed
      } else if (width >= 375 && width < 430) {
        setMarginTop("130px"); // iPhone XR, iPhone 12 Pro, iPhone 14 Pro Max
      } else if (width >= 360 && width < 412) {
        setMarginTop("125px"); // Samsung Galaxy S8+, Samsung Galaxy S20 Ultra
      } else if (width >= 411 && width < 600) {
        setMarginTop("135px"); // Samsung Galaxy A51/71, Nest Hub, Nest Hub Max
      } else if (width <= 320) {
        setMarginTop("120px"); // iPhone SE
      } else {
        setMarginTop("140px");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Sync selectedOptions when selectedItems change (to reflect initial state)
  useEffect(() => {
    if (selectedItems[index]?.occasions) {
      setSelectedOptions(selectedItems[index]?.occasions.split(", "));
    }
  }, [selectedItems, index]);

  return (
    <div className={classes.itemBody}>
      <div className={classes.details}>
        <div className={classes.imgWrapper}>
          <IconComponent
            iconTitle="CrossIcon"
            className={classes.crossIcon}
            onClick={(event) => {
              event.stopPropagation();
              handleSelectItem(item, item.index - 1);
            }}
          />
          <img
            className={classes.imgBody}
            src={`${item?.src?.name ? URL.createObjectURL(item.src) : item.src}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = noImageLogo;
            }}
          />
          <ImageUpload className={classes.imgBody} handleImageSelected={handleImageSelected} />
        </div>
        <div className={classes.inputLabel}>{"Item Name:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`item${index}`}
            value={item.itemName ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "itemName")}
          />
        </div>

        <div className={classes.inputLabel}>
          {"Category: "}
          <span>{"*"}</span>
        </div>
        <div
          id={`category${index}`}
          className={`${classes.selectInputWrapper} ${inputErrors[index].category ? classes.errorInputWrapper : classes.validInputWrapper
            }`}
        >
          <CategoriesDropDown
            selectedCategory={item.isCategoryModified ? item.subCategory : item.category}
            handleOpen={handleClick}
            handleClose={handleClose}
            open={open}
            categoriesList={categoriesList}
            handleMenuClick={handleMenuClick}
          />
        </div>

        {/* from here */}
        <div className={classes.inputLabel}>
          {"Style Type: (Multi-Select) "}
          <span>{"*"}</span>
        </div>
        <div className="multi-select-options multi-select-dropdown">
          {selectedOptions.length > 0 && (
            <div
              style={{
                height: "auto",
                marginBottom: "0px",
                padding: selectedOptions.length ? "18px 8px" : "0px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
                fontSize: "large",
                flexWrap: "wrap",
              }}
              className={
                "selected-options " +
                `${classes.inputWrapper} ${classes.validInputWrapper}`
              }
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {selectedOptions.slice(0, 3).map((option) => (
                  <span key={option} className="selected-option">
                    {options.find((opt) => opt.value === option).label}
                  </span>
                ))}
                {selectedOptions.length > 3 && (
                  <span className="selected-option">{`..+${selectedOptions.length - 3}`}</span>
                )}
              </div>
            </div>
          )}
          <div className={`multi-select-option-group ${classes.dropDownOptions}`}>
            {options.map((option) => (
              <label key={option.value} className="multi-select-option">
                <input
                  type="checkbox"
                  id={option.value}
                  value={option.value}
                  checked={selectedOptions.includes(option.value)}
                  onChange={handleChange}
                  className="custom-checkbox"
                />
                <span
                  style={{
                    marginLeft: "8px",
                  }}>{option.label}</span>
              </label>
            ))}
          </div>
        </div>

        {/* style-type */}

        <div className={classes.infoWrapper}>
          <div
            style={{
              marginTop: marginTop,
            }}
            className={classes.inputLabel}
          >
            {"Size:"}
          </div>
          <a
            data-tooltip-id="sizeInfo-tooltip"
            data-tooltip-content='If size is not available please enter "NA"'
          >
            <IconComponent
              style={{
                marginTop: marginTop,
              }}
              iconTitle={"InfoIcon"}
            />
          </a>
        </div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`size${index}`}
            value={item.size ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "size")}
          />
        </div>
        <div className={classes.inputLabel}>{"Brand Name:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`brand${index}`}
            value={item.brand ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "brand")}
          />
        </div>
        <div className={classes.inputLabel}>{"Store Name:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`store${index}`}
            value={item.store ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "store")}
          />
        </div>

        <div className={classes.inputLabel}>{"Color:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`color${index}`}
            value={item.color ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "color")}
          />
        </div>

        <div className={classes.inputLabel}>{"Price:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`price${index}`}
            value={item.price ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "price")}
          />
        </div>

        <div className={classes.inputLabel}>{"Date Purchased:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              className={classes.datePicker}
              inputFormat="MM/DD/YYYY"
              disableFuture
              orientation="portrait"
              value={item.datePurchased ?? null}
              onChange={(value) =>
                handleValueChange(new Date(value).toISOString(), "datePurchased")
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>



        <div className={classes.inputLabel}>{"Link to Item:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`productLink${index}`}
            value={item.productLink ?? ""}
            onChange={(e) => handleValueChange(e.target.value, "productLink")}
          />
        </div>

        <div className={classes.inputLabel}>{"Tags:"}</div>
        <div className={`${classes.inputWrapper} ${classes.validInputWrapper}`}>
          <input
            className={classes.textInput}
            type="text"
            id={`tags${index}`}
            value={item.tags ?? ""}
            placeholder="#tag_name1 #tag_name2"
            onChange={(e) => handleValueChange(e.target.value, "tags")}
          />
        </div>
      </div>
    </div>
  );
}
