import { makeStyles } from "@material-ui/styles";
import { tabMediaStyle, mobileMediaStyle } from "../helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  overflow: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    top: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalContainer: {
    backgroundColor: "#ffffff",
    minWidth: "400px",
    padding: "15px",
    fontFamily: "var(--primaryRegularFont)",
  },

  modalTitle: {
    height: "35px",
    fontSize: "20px",
    lineHeight: "30px",
  },

  avatarContainer: {
    display: "flex",
    "& > div:first-child": {
      "& > div": {
        width: "400px !important",
        maxHeight: "350px !important",
      },
      "& canvas": {
        maxHeight: "350px",
        objectFit: "contain",
      },
    },
  },

  avatarPreview: {
    width: "200px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
  },

  previewTitle: {
    fontSize: "15px",
    fontWeight: "bold",
  },

  previewWrapper: {
    height: "156px",
    width: "156px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "1px solid #C6C6C6",
    borderRadius: "78px",
    boxShadow: "0 0 5px #000000",
  },

  preview: {
    height: "150px",
    width: "150px",
    borderRadius: "75px",
  },

  buttonContainer: {
    display: "flex",
    paddingTop: "20px",
    justifyContent: "flex-end",
    columnGap: "10px",
  },

  LabelIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    filter: "invert(0.8)",
    cursor: "pointer",
  },

  uploadIcon: {
    width: "80px",
    height: "80px",
  },

  ...tabMediaStyle({
    avatarContainer: {
      flexDirection: "column",
      alignItems: "center",
    },

    avatarPreview: {
      paddingTop: "20px",
    },
  }),

  ...mobileMediaStyle({
    modalContainer: {
      minWidth: "280px",
      padding: "10px",
    },

    avatarContainer: {
      "& > div:first-child": {
        "& > div": {
          width: "280px !important",
          maxHeight: "240px !important",
        },
        "& canvas": {
          maxHeight: "240px",
        },
      },
      "& > label": {
        width:"100%",
        height:"100%"
      }
    },
    modalTitle: {
      height: "30px",
      fontSize: "16px",
      lineHeight: "24px",
    },
  }),
});
