import women from "../../assets/images/dashboard/women/index";
import men from "../../assets/images/dashboard/men/index";
import neutral from "../../assets/images/dashboard/neutral/index";

const {
  AccessoriesWomen,
  ActivewearWomen,
  BeachwearWomen,
  BeautyWomen,
  DenimWomen,
  DressesWomen,
  HangerWomen,
  HangerWomenNew,
  HomeWomen,
  JumpsuitWomen,
  LoungewearWomen,
  MiscellaneousWomen,
  OuterwearWomen,
  PantsWomen,
  ShoesWomen,
  ShortsWomen,
  SkirtsWomen,
  SweatersWomen,
  TopsWomen,
} = women;

const {
  AccessoriesMen,
  ActivewearMen,
  BeachwearMen,
  BeautyMen,
  DenimMen,
  HangerMen,
  HangerMenNew,
  MiscellaneousMen,
  OuterwearMen,
  PantsMen,
  ShirtsMen,
  ShoesMen,
  ShortsMen,
  SuitsMen,
} = men;

const {
  AccessoriesNeutral,
  ActivewearNeutral,
  BeachwearNeutral,
  BeautyNeutral,
  DenimNeutral,
  DressesNeutral,
  HangerNeutral,
  HomeNeutral,
  LoungewearNeutral,
  MiscellaneousNeutral,
  OuterwearNeutral,
  PantsNeutral,
  ShirtsNeutral,
  ShoesNeutral,
  ShortsNeutral,
  SkirtsNeutral,
  SweatersNeutral,
  TopsNeutral,
} = neutral;

export const MenCategories = [
  { name: "Activewear", img: ActivewearMen },
  { name: "Accessories", img: AccessoriesMen },
  { name: "Beauty", img: BeautyMen },
  { name: "Beachwear", img: BeachwearMen },
  { name: "Denim", img: DenimMen },
  { name: "Miscellaneous", img: MiscellaneousMen },
  { name: "Outerwear", img: OuterwearMen },
  { name: "Pants", img: PantsMen },
  { name: "Shirts", img: ShirtsMen },
  { name: "Shoes", img: ShoesMen },
  { name: "Shorts", img: ShortsMen },
  { name: "Suits", img: SuitsMen },
];

export const WomenCategories = [
  { name: "Activewear", img: ActivewearWomen },
  { name: "Accessories", img: AccessoriesWomen },
  { name: "Beauty", img: BeautyWomen },
  { name: "Beachwear", img: BeachwearWomen },
  { name: "Denim", img: DenimWomen },
  { name: "Dresses", img: DressesWomen },
  { name: "Hand Bags", img: HomeWomen },
  { name: "Jumpsuit", img: JumpsuitWomen },
  { name: "Loungewear", img: LoungewearWomen },
  { name: "Miscellaneous", img: MiscellaneousWomen },
  { name: "Outerwear", img: OuterwearWomen },
  { name: "Pants", img: PantsWomen },
  { name: "Shoes", img: ShoesWomen },
  { name: "Shorts", img: ShortsWomen },
  { name: "Skirts", img: SkirtsWomen },

  { name: "Tops", img: TopsWomen },
];

export const NeutralCategories = [
  { name: "Activewear", img: ActivewearNeutral },
  { name: "Accessories", img: AccessoriesNeutral },
  { name: "Beauty", img: BeautyNeutral },
  { name: "Beachwear", img: BeachwearNeutral },
  { name: "Denim", img: DenimNeutral },
  { name: "Dresses", img: DressesNeutral },
  { name: "Hand Bags", img: HomeNeutral },
  { name: "Loungewear", img: LoungewearNeutral },
  { name: "Miscellaneous", img: MiscellaneousNeutral },
  { name: "Outerwear", img: OuterwearNeutral },
  { name: "Pants", img: PantsNeutral },
  { name: "Shirts", img: ShirtsNeutral },
  { name: "Shoes", img: ShoesNeutral },
  { name: "Shorts", img: ShortsNeutral },
  { name: "Skirts", img: SkirtsNeutral },

  { name: "Tops", img: TopsNeutral },
];

export const hanger = {
  // female: HangerWomen,
  // male: HangerMen,
  neutral: HangerNeutral,
  female: HangerWomenNew,
  male: HangerMenNew,
};
