import { gql } from "@apollo/client";

export const getTrendingCreators = gql`
  query GetTrendingCreators($limit: Int!) {
    getTrendingCreators(limit: $limit) {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
        action
      }
    }
  }
`;

export const sendFollowRequest = gql`
  mutation SendFollowRequest($receiverId: String!) {
    sendFollowRequest(receiverId: $receiverId) {
      statusCode
      message
    }
  }
`;

export const getFollowRequests = gql`
  query GetFollowRequests {
    getFollowRequests {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
      }
    }
  }
`;

export const acceptFollowRequest = gql`
  mutation AcceptFollowRequest($senderId: String!) {
    acceptFollowRequest(senderId: $senderId) {
      statusCode
      message
    }
  }
`;

export const rejectFollowRequest = gql`
  mutation RejectFollowRequest($senderId: String!) {
    rejectFollowRequest(senderId: $senderId) {
      statusCode
      message
    }
  }
`;

export const unFollowCreators = gql`
  mutation UnFollowCreators($receiverId: String!) {
    unFollowCreators(receiverId: $receiverId) {
      statusCode
      message
    }
  }
`;

export const getfollowBackLists = gql`
  query GetfollowBackLists {
    getfollowBackLists {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
      }
    }
  }
`;
export const getFollowersLists = gql`
  query GetFollowersLists {
    getFollowersLists {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
        followStatus
      }
    }
  }
`;

export const getFollowingLists = gql`
  query GetFollowingLists {
    getFollowingLists {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
        followStatus
      }
    }
  }
`;

export const getPendingRequest = gql`
  query GetPendingRequest {
    getPendingRequest {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
        followStatus
      }
    }
  }
`;

export const getUserDetailsByUsername = gql`
  query GetUserDetailsByUsername($userName: String!) {
    getUserDetailsByUsername(userName: $userName) {
      statusCode
      message
      error
      data {
        id
        firstName
        lastName
        email
        profilePicUrl
        bio
        isFollowing
        isFollowed
      }
    }
  }
`;

export const getFollowersListsbyUsername = gql`
  query GetFollowersLists($userName: String) {
    getFollowersLists(userName: $userName) {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
        action
        followStatus
        followingStatus
        myUserId
      }
    }
  }
`;

export const getFollowingListsbyUsername = gql`
  query GetFollowingLists($userName: String) {
    getFollowingLists(userName: $userName) {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
        followStatus
        followingStatus
        myUserId
      }
    }
  }
`;

export const getCurationsListByUsername = gql`
  query GetCurationsList($userName: String) {
    getCurationsList(userName: $userName) {
      statusCode
      message
      error
      data
    }
  }
`;

export const getItemsFromClosetByUsername = gql`
  query GetItemsFromCloset($category: [String]!, $userName: String) {
    getItemsFromCloset(category: $category, userName: $userName) {
      id
      brand
      store
      itemName
      color
      size
      tags
      src
      productUrl
      productStoreUrl
      isLiked
      category
      createdAt
      modifiedAt
      datePurchased
      price
    }
  }
`;

export const getCreatorsQuery = gql`
  query GetCreatorsQuery($searchTerm: String, $limit: Int) {
    getCreatorsQuery(searchTerm: $searchTerm, limit: $limit) {
      statusCode
      message
      error
      data {
        id
        username
        name
        profileUrl
        action
        followStatus
        followingStatus
        myUserId
        isFollowed
      }
    }
  }
`;
