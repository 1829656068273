import { useEffect, useState } from "react";
import styled from "styled-components";
import IconComponent from "../../../components/fields/icon";
import GlobalStyles from "../../../styles/global";
import { modalTypes } from "../modal/constant";
import { updateUserProfile } from "../../../apollo/operations/user";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import HowItWork from "../../../pages/dashboard/HowItWorkGuide";
import { useStyles } from "./styles";
import Button from "../../../components/fields/button";

const { mediaQueries } = GlobalStyles;

const Overflow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 20;
`;

const ModalContainer = styled.div`
  position: absolute;
  width: 800px;
  min-height: 420px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  @media screen and (${mediaQueries.tablet}) {
    width: 450px;
    padding: 18px 24px;
  }

  @media screen and (${mediaQueries.mobile}) {
    width: 300px;
    min-height: 300px;
  }
`;

const HeaderSvg = styled.div`
  background: #f7f7f7;
  border-radius: 5px;
  height: 229px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (${mediaQueries.tablet}) {
    height: 150px;
    margin-bottom: 18px;
    > i {
      > svg {
        height: 120px;
      }
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    height: 128px;
    > i {
      > svg {
        height: 95px;
      }
    }
  }
`;

const MainText = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  justify-content: center;
  color: #FB5B01;
  margin-bottom: 12px;

  @media screen and (${mediaQueries.tablet}) {
    font-size: 20px;
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 18px;
  }
`;

const SubText = styled.div`
  font-family: var(--primaryRegularFont);
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: left;
  color: #000000;
  padding: 0 40px 40px;

  p:first-of-type {
    margin-bottom: 40px; /* Add space below the first paragraph */
  }

  p:nth-of-type(2), 
  p:nth-of-type(3) {
    padding-left: 20px;
  }

  p {
    margin: 16px 0;  /* General spacing for other paragraphs */
  }

  @media screen and (${mediaQueries.tablet}) {
    font-size: 14px;
    line-height: 20px;
    padding: 0 24px 24px;
    p:first-of-type {
      margin-bottom: 25px; /* Adjust space for tablet screens */
    }

    p:nth-of-type(2), 
    p:nth-of-type(3) {
      padding-left: 15px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 12px;
    line-height: 18px;
    padding: 0 16px 16px;
    p:first-of-type {
      margin-bottom: 20px; /* Adjust space for mobile screens */
    }

    p:nth-of-type(2), 
    p:nth-of-type(3) {
      padding-left: 10px;
    }
  }
`;

const ButtonContainter = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: 12px;
`;

const DoNotShowAgainCheckbox = styled.div`
  font-family: var(--primaryRegularFont);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  margin-right: 0px;

  input[type="checkbox"] {
    border: 2px solid #CACACA;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 5px;
    cursor: pointer;
    width: 20px !important; /* Adjusted width */
    height: 20px !important; /* Adjusted height */
    background-color: white;
    position: relative;
    display: inline-block;
    background-size: 20px 20px; /* Adjusted size to match new dimensions */
    background-position: center;
    margin-right: 8px;
    transition: background 0.3s ease;

    &:checked {
      background-image: url('data:image/svg+xml,%3Csvg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.79203 0.332031H4.20536C1.7787 0.332031 0.332031 1.7787 0.332031 4.20536V9.78536C0.332031 12.2187 1.7787 13.6654 4.20536 13.6654H9.78536C12.212 13.6654 13.6587 12.2187 13.6587 9.79203V4.20536C13.6654 1.7787 12.2187 0.332031 9.79203 0.332031ZM10.1854 5.46536L6.40536 9.24536C6.35903 9.29189 6.30397 9.3288 6.24333 9.35399C6.1827 9.37918 6.11769 9.39214 6.05203 9.39214C5.98637 9.39214 5.92136 9.37918 5.86073 9.35399C5.80009 9.3288 5.74503 9.29189 5.6987 9.24536L3.81203 7.3587C3.76291 7.31292 3.72351 7.25772 3.69618 7.19639C3.66885 7.13506 3.65415 7.06885 3.65297 7.00171C3.65179 6.93458 3.66414 6.86789 3.68928 6.80563C3.71443 6.74337 3.75186 6.68682 3.79934 6.63934C3.84682 6.59186 3.90337 6.55443 3.96563 6.52928C4.02789 6.50414 4.09458 6.49179 4.16171 6.49297C4.22885 6.49415 4.29506 6.50885 4.35639 6.53618C4.41772 6.5635 4.47292 6.60291 4.5187 6.65203L6.05203 8.18536L9.4787 4.7587C9.57348 4.67038 9.69884 4.6223 9.82838 4.62458C9.95791 4.62687 10.0815 4.67934 10.1731 4.77095C10.2647 4.86256 10.3172 4.98615 10.3195 5.11568C10.3218 5.24522 10.2737 5.37058 10.1854 5.46536Z" fill="%23fb5b0180"/%3E%3C/svg%3E') !important;
      border: none;
    }

    &:focus {
      outline: none;
    }
  }

  label {
    font-size: 16px;
  }

  @media screen and (${mediaQueries.tablet}) {
    font-size: 16px;

    label {
      font-size: 14px;
    }
  }

  @media screen and (${mediaQueries.mobile}) {
    font-size: 14px;

    label {
      font-size: 12px;
    }

    input[type="checkbox"] {
      width: 15px;
      height: 15px;
    }
  }
`;

export default function ScanEmailModal(props) {
  const { type, svgType = "", handleButtonClick, isChatResults } = props;
  const modalType = modalTypes[type];
  const [showHowItWork, setShowHowIsWork] = useState(false);

  const localStorage = window.localStorage.getItem("doNotShowAgainEmptyReceiptPopup");
  const { showEmptyReceiptPopup } = useSelector((state) => state.showPopup);
  
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [updateShowPopup] = useMutation(updateUserProfile);
  const classes = useStyles();

  // useEffect(() => {
  //   const isDoNotShowAgain = localStorage.getItem("doNotShowAgain");
  //   if (isDoNotShowAgain) {
  //     setDoNotShowAgain(true); // Ensure the checkbox reflects the saved state
  //     handleButtonClick(false); // Close modal if it's set in localStorage
  //   }
  // }, [handleButtonClick]);

  function updateBackendAndLocalStorage() {
    window.localStorage.setItem("doNotShowAgainEmptyReceiptPopup", doNotShowAgain);
    updateShowPopup({
      variables: { userDetails: { emptyReceiptPopup: !doNotShowAgain } },
    });
  }

  const handleOutSideClick = (e) => {
    // Close the modal only if clicking outside the modal content
    if (e.target === e.currentTarget) {
      handleButtonClick(false);
    }
    updateBackendAndLocalStorage()
  };

  const handleCheckboxChange = (event) => {
    setDoNotShowAgain(event.target.checked);
    if (event.target.checked) {
      setDoNotShowAgain(!doNotShowAgain);
    }
  };

  function handleHowItWorks() {
    setShowHowIsWork(true);
  }

  const handleHowWorkResponse = (value) => {
    if (!value) {
      setShowHowIsWork(false);
    }
  };
  const handleCancelButton = () => {
    handleButtonClick(false);
    updateBackendAndLocalStorage()
  }
  return localStorage === "true" ? (
    ""
  ) : showEmptyReceiptPopup ? (
    <Overflow onClick={handleOutSideClick}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <IconComponent iconTitle={modalType?.headerSvg} />
        {modalType?.mainText && <MainText>{modalType.mainText}</MainText>}
        <SubText>
          <div>
            <p>To Get Started with Mikloset:</p>
            <p>1. Forward your retail e-receipts to @mikloset.com.</p>
            <p>
              2. After sending your e-receipts, navigate to <strong>&lsquo;Add Your Style&rsquo;</strong> and
              click on the @mikloset email address to begin.
            </p>
            <p>
              For detailed instructions, click <a className={classes.howItWorks} onClick={handleHowItWorks}>How It Works</a>.
            </p>
            <p>
              * Note: For optimal results, use receipts with images and detailed descriptions.
            </p>
          </div>
        </SubText>

        <DoNotShowAgainCheckbox>
          <input
            type="checkbox"
            id="do-not-show-again"
            checked={doNotShowAgain}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="do-not-show-again">Do Not Show Again</label>
        </DoNotShowAgainCheckbox>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
        <Button
            buttontype="secondary"
            size="default"
            onClick={handleCancelButton}
        >
          {"Cancel"}
        </Button>
        </div>
      </ModalContainer>
      {showHowItWork && <HowItWork modalResponse={handleHowWorkResponse} />}
    </Overflow>
  ) : (
    <div>{""}</div>
  );
}
