import { useState, useEffect } from "react";
import CategoriesDropDown from "../../../components/categoriesDropDown";
import { useStyles } from "./styles";
import IconComponent from "../../../components/fields/icon";
import { Tooltip } from "react-tooltip";
import { validatePrice, validateTags } from "../../../components/helpers/validate";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function DetailSection(props) {
  const options = [
    { value: "Business Casual", label: "Business Casual" },
    { value: "Casual Wear", label: "Casual Wear" },
    { value: "Date Night", label: "Date Night" },
    { value: "Formal Wear", label: "Formal Wear" },
    { value: "Summer Time", label: "Summer Time" },
    { value: "Party Wear", label: "Party Wear" },
  ];

  const classes = useStyles();
  const { itemDetails, setItemDetails, categoriesList } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const open = Boolean(anchorEl);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleMenuClick(e) {
    setItemDetails({ ...itemDetails, subCategory: e });
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    return setAnchorEl(event.currentTarget);
  }

  function handleValueChange(value, type) {
    if (type === "size") {
      if (value.length > 7) {
        return;
      }
      value = value.toUpperCase();
    } else if (type === "tags") {
      if (
        (value.length === 1 && value === " ") ||
        (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
      ) {
        return;
      } else if (value.length === 1 && value !== "#") {
        value = "#" + value;
      } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
        value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
      }

      // to handle when user paste the tags
      if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
        value = validateTags(value.trim());
      }
    } else if (type === "price") {
      if (value.length === 1 && value === ".") {
        value = "$0" + value;
      } else if (value.length === 1 && value.match(/\d/g)) {
        value = "$" + value;
      } else if (value !== "" && !validatePrice(value)) {
        return;
      }
    }
    setItemDetails({ ...itemDetails, [type]: value });
  }

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value],
    );
    let changedSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
    handleValueChange(changedSelectedOptions.join(", "), "occasions");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (isOpen && !event.target.closest(".multi-select-dropdown")) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  return (
    <div className={classes.detailsParent}>
      <div className={classes.additionalInfoText}>
        {"Add Additional Information for a better curated experience"}
      </div>
      <div className={classes.detailsBody}>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>
            {"Category: "}
            <span>{"*"}</span>
          </div>
          <div className={classes.selectInputWrapper}>
            <CategoriesDropDown
              selectedCategory={itemDetails.subCategory}
              handleClose={handleClose}
              handleOpen={handleClick}
              categoriesList={categoriesList}
              open={open}
              handleMenuClick={handleMenuClick}
            />
          </div>
        </div>
        {/* 1 */}

        {/* 1 end */}
        {/* 2 */}
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>
          {"Style Type: (Multi-Select) "}<span>{"*"}</span>
          </div>
          <div className="multi-select-options multi-select-dropdown">
            <div
              style={{
                height: "auto",
                marginBottom: "0px",
                padding: selectedOptions.length ? "16.5px 10px" : "26px 10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
                flexWrap: "wrap",
              }}
              className={
                "selected-options " + `${classes.inputWrapper} ${classes.validInputWrapper}`
              }
              onClick={toggleDropdown}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {selectedOptions.slice(0, isMobile ? 2 : 3).map((option) => (
                  <span
                    key={option}
                    className="selected-option"
                    style={{ whiteSpace: "normal" }}
                  >
                    {options.find((opt) => opt.value === option).label}
                  </span>
                ))}
                {selectedOptions.length > (isMobile ? 2 : 3) && (
                  <span
                    className="selected-option"
                    style={{ whiteSpace: "normal"}}
                  >
                    {`..+${selectedOptions.length - (isMobile ? 2 : 3)}`}
                  </span>
                )}
              </div>
              <span className={classes.dropDownArrow}>
                {isOpen ? <ArrowDropUpIcon color="action" /> : <ArrowDropDownIcon color="action" />}
              </span>
            </div>
            {isOpen && (
              <div className={`multi-select-option-group ${classes.dropDownOptions}`}>
                {options.map((option) => (
                  <label key={option.value} className="multi-select-option">
                    <input
                      type="checkbox"
                      id={option.value}
                      value={option.value}
                      checked={selectedOptions.includes(option.value)}
                      onChange={handleChange}
                      className="custom-checkbox"
                    />
                    <label htmlFor={option.value}>{option.label}</label>
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* 2 end */}
      </div>

      <div className={classes.detailsBody}>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Brand Name:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.brand}
              onChange={(e) => handleValueChange(e.target.value, "brand")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Store Name:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.storeName}
              onChange={(e) => handleValueChange(e.target.value, "storeName")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Item Name:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.itemName}
              onChange={(e) => handleValueChange(e.target.value, "itemName")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={`${classes.inputLabel} ${classes.infoContainer}`}>
            <div>{"Size:"}</div>
            <a
              data-tooltip-id="info-tooltip"
              data-tooltip-content='If size is not available please enter "NA"'
            >
              <IconComponent iconTitle={"InfoIcon"} />
            </a>
            <Tooltip
              id="info-tooltip"
              style={{
                backgroundColor: "#D1D1D1",
                color: "#000000",
                opacity: "1",
                fontFamily: "var(--primaryRegularFont)",
              }}
            />
          </div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.size}
              onChange={(e) => handleValueChange(e.target.value, "size")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Color:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.color}
              onChange={(e) => handleValueChange(e.target.value, "color")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Price:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              placeholder="$ 99.99"
              value={itemDetails.price}
              onChange={(e) => handleValueChange(e.target.value, "price")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Date Purchased:"}</div>
          <div className={classes.inputWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                className={classes.datePicker}
                inputFormat="MM/DD/YYYY"
                disableFuture
                defaultValue={null}
                value={itemDetails.datePurchased ?? null}
                onChange={(value) => handleValueChange(value, "datePurchased")}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Tags:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              placeholder="#tag_name1 #tag_name2"
              value={itemDetails.tags}
              onChange={(e) => handleValueChange(e.target.value, "tags")}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.inputLabel}>{"Link to Item:"}</div>
          <div className={classes.inputWrapper}>
            <input
              className={classes.textInput}
              type="text"
              value={itemDetails.productLink || ""}
              onChange={(e) => handleValueChange(e.target.value, "productLink")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
