import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { useMutation } from "@apollo/client";
import {
  updateNotificationStatus,
  updateUnreadNotifications,
} from "../../apollo/operations/notifications";
import { openToast } from "../../components/toast";
import ModalPortal from "../../containers/common/modalPortal";
import Modal from "../../containers/common/modalPortal/modal";
import { useDispatch, useSelector } from "react-redux";
import useNotification from "../../hooks/useNotification";
import { sortingSection } from "../../components/helpers/sortHelper";
import { clearNotification } from "../../store/socketSlice";
import NotificationRow from "./NotificationRow";
import LeftNavBar from "../settings/leftNavBar";
import { NotificationNavOptions } from "../../components/common/constants";
import DropDown from "../../components/MobieViewDropDown/DropDown";
import { useNavigate } from "react-router-dom";

export default function Notifications() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchNotifications, notificationsData } = useNotification();
  const pushNotification = useSelector((state) => state.socket.notifications);
  const [displayDeleteNotification, setDisplayDeleteNotification] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [notificationsList, setNotificationsList] = useState([]);
  const [updateAllUnreadNotifications, { data: unreadResponse }] =
    useMutation(updateUnreadNotifications);
  const [updateNotification, { data: updateStatus }] = useMutation(updateNotificationStatus);
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeOption, setActiveOption] = useState(NotificationNavOptions[0].value);
  const [options, setOptions] = useState(NotificationNavOptions);
  const [lookName, setLookName] = useState(NotificationNavOptions[0].name);

  function sortByDate(rowNotificationsList) {
    const sortedNotificationArray = [];
    for (let ele of rowNotificationsList) {
      const key = dayjs(new Date(ele.time)).format("MM/DD/YYYY");
      const index = sortedNotificationArray.findIndex((ele) => ele.date === key);
      if (index !== -1) {
        sortedNotificationArray[index].notifications.push({ ...ele });
      } else {
        sortedNotificationArray.push({ date: key, notifications: [{ ...ele }] });
      }
    }
    sortedNotificationArray.sort((a, b) =>
      sortingSection(new Date(a.date).getTime(), new Date(b.date).getTime(), false),
    );
    sortedNotificationArray.forEach((item) =>
      item.notifications.sort((a, b) =>
        sortingSection(new Date(a.time).getTime(), new Date(b.time).getTime(), false),
      ),
    );
    return sortedNotificationArray;
  }

  useEffect(() => {
    updateAllUnreadNotifications();
    dispatch(clearNotification());
  }, []);

  useEffect(() => {
    fetchNotifications(activeOption);
  }, [activeOption]);

  useEffect(() => {
    if (unreadResponse) fetchNotifications();
  }, [unreadResponse]);

  useEffect(() => {
    if (notificationsData?.getNotificationDetails) {
      if (notificationsData?.getNotificationDetails?.statusCode === "200") {
        setNotificationsList(sortByDate(notificationsData.getNotificationDetails.data));
      } else {
        openToast("error", "Failed!", "Failed to fetch notifications");
      }
    }
  }, [notificationsData]);

  useEffect(() => {
    if (updateStatus && isDeleting) {
      if (updateStatus.updateNotificationDetails.statusCode === "200") {
        openToast("success", "Deleted", "Notification is successfully deleted");
      } else {
        openToast("error", "Error", "There is an error, in delete notification");
      }
      setIsDeleting(false);
      fetchNotifications();
    }
  }, [updateStatus]);

  function dateHeadingConverter(dateValue) {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    if (new Date(dateValue).getTime() === today.getTime()) {
      return "Today";
    } else if (
      new Date(dateValue).getTime() === new Date(today.setDate(today.getDate() - 1)).getTime()
    ) {
      return "Yesterday";
    } else return dateValue;
  }

  function handleModalResponse(value) {
    // console.log("response of delete confirmation modal popup ----", value);
    if (value) {
      // call the delete notification API
      setIsDeleting(true);
      updateNotification({
        variables: {
          notificationId: selectedNotification.notificationId,
          isDeleted: true,
        },
      });
    }
    setDisplayDeleteNotification(false);
  }

  function checkToday(dateValue) {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const passedDate = new Date(new Date(dateValue).setHours(0, 0, 0, 0));
    return passedDate.getTime() === today.getTime();
  }

  useEffect(() => {
    if (activeOption === "followerRequests") {
      navigate("/followme/follow_requests");
    }
  }, [activeOption]);

  return (
    <div className={classes.notificationBody}>
      <div className={classes.header}>NOTIFICATIONS</div>
      <div className={classes.BodyWrapper}>
        <LeftNavBar
          setActiveOption={setActiveOption}
          options={options}
          setOptions={setOptions}
          activeOption={activeOption}
        />
        <DropDown
          defaultName={"Notifications"}
          dropDownList={options}
          setDropDownList={setOptions}
          setLookName={setLookName}
          handleSelection={(item) => setActiveOption(item.value)}
        />
        <div className={classes.rightBody}>
          <div className={classes.sectionHeader}>{lookName}</div>
          <hr className={classes.sectionHr} />
          <div className={classes.rightHeader} />
          <div className={classes.rightSubBody}>
            {!!pushNotification.length && (
              <div className={classes.dayWrapper}>
                <div className={classes.dateHeader}>New Notifications</div>
                {pushNotification.map((newInfo, i) => (
                  <NotificationRow
                    notification={newInfo}
                    key={i}
                    setDisplayDeleteNotification={setDisplayDeleteNotification}
                    setSelectedNotification={setSelectedNotification}
                    isToday={checkToday(newInfo.time)}
                    updateNotification={updateNotification}
                  />
                ))}
              </div>
            )}
            {notificationsList.length > 0 ? (
              <>
                {notificationsList?.map((dayNotification, index) => (
                  <div className={classes.dayWrapper} key={index}>
                    <div className={classes.dateHeader}>
                      {dateHeadingConverter(dayNotification.date)}
                    </div>
                    {dayNotification?.notifications?.map((notification, index) => (
                      <NotificationRow
                        key={index}
                        notification={notification}
                        setDisplayDeleteNotification={setDisplayDeleteNotification}
                        isToday={checkToday(dayNotification.date)}
                        setSelectedNotification={setSelectedNotification}
                        updateNotification={updateNotification}
                      />
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <div className={classes.emptyNotification}>No Notifications!</div>
            )}
          </div>
        </div>
      </div>
      {displayDeleteNotification && (
        <ModalPortal>
          <Modal
            type={"deleteNotification"}
            handleButtonClick={handleModalResponse}
            svgType="circle"
          />
        </ModalPortal>
      )}
    </div>
  );
}
