import ActivewearWomen from "./Activewear_Women.jpg";
import AccessoriesWomen from "./Accessories_Women.jpg";
import BeautyWomen from "./Beauty-Womens.jpg";
import BeachwearWomen from "./Beachwear_Women.jpg";
import DenimWomen from "./Denim_Women_1.jpg";
import DressesWomen from "./Dress_Women.jpg";
import JumpsuitWomen from "./Jumpsuit2.jpg";
import LingerieWomen from "./lingerie_women.png";
import LoungewearWomen from "./loungewear_women.png";
import MiscellaneousWomen from "./Misc_Women.jpg";
import OuterwearWomen from "./Outerwear2.jpg";
import PantsWomen from "./Pants.png";
import RompersWomen from "./rompers_women.png";
import ShoesWomen from "./Shoes_Women.jpg";
import ShortsWomen from "./Shorts_Women.jpg";
import SkirtsWomen from "./Skirts.png";
import SweatersWomen from "./sweaters_women.png";
import TopsWomen from "./Tops_Women.jpg";
import HangerWomen from "./hanger_women.png";
import HangerWomenNew from "./hanger_women.jpg";
import HomeWomen from "./Hangbag.png";

export default {
  ActivewearWomen,
  AccessoriesWomen,
  BeautyWomen,
  BeachwearWomen,
  DenimWomen,
  DressesWomen,
  JumpsuitWomen,
  LingerieWomen,
  LoungewearWomen,
  MiscellaneousWomen,
  OuterwearWomen,
  PantsWomen,
  RompersWomen,
  ShoesWomen,
  ShortsWomen,
  SkirtsWomen,
  SweatersWomen,
  TopsWomen,
  HangerWomen,
  HangerWomenNew,
  HomeWomen,
};
