import { useState, useEffect } from "react";
import { InputField } from "../../../components/signin";
import { useStyles } from "./styles";
import UserAvatar from "./userAvatar";
import TextArea from "../../../components/fields/textArea";
import { validateEmail, validateNameLength } from "../../../components/helpers/validate";
import Button from "../../../components/fields/button";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getUserProfile, updateUserProfile } from "../../../apollo/operations/user";
import { openToast } from "../../../components/toast";
import Loader from "../../../components/loaderScreen";
import { uploadImage } from "../../../apollo/operations/items";
import ModalPortal from "../../../containers/common/modalPortal";
import PasswordUpdatePopup from "./passwordUpdatePopup";
import { checkChange, fetchChanges } from "../../../components/helpers/productHelper";
var Buffer = require("buffer/").Buffer;

export default function Account() {
  const classes = useStyles();
  const [apiError, setApiError] = useState("");
  const [getProfile, { data: userProfile }] = useLazyQuery(getUserProfile);
  const [updateProfile, { data: userUpdated }] = useMutation(updateUserProfile);
  const [Upload, { data: uploadResponse }] = useLazyQuery(uploadImage);
  const [user, setUser] = useState({});
  const [image, setImage] = useState("");
  const [imageFileDetails, setImageFileDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [inputErrors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    userName: false,
  });

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (userProfile) {
      if (
        userProfile.getUserDetails.statusCode !== "200" ||
        userProfile.getUserDetails.message !== "Fetched Successfully"
      ) {
        openToast("error", "Failed", "Try again later");
        return;
      }
      setUser(JSON.parse(JSON.stringify(userProfile.getUserDetails.data)));
    }
  }, [userProfile]);

  useEffect(() => {
    if (userUpdated) {
      if (
        userUpdated.updateUserDetails.statusCode === "200" &&
        userUpdated.updateUserDetails.message === "Updated Successfully"
      ) {
        setIsLoading(false);
        openToast("success", "Successfully Updated", "User details is successfully updated");
      } else {
        setIsLoading(false);
        openToast("error", "Failed", "Try again later");
      }
    }
  }, [userUpdated]);

  useEffect(() => {
    if (uploadResponse) {
      if (uploadResponse.getPresignedUploadURL) {
        uploadImageUsingPresignedURl(uploadResponse);
      } else {
        setIsLoading(false);
      }
    }
  }, [uploadResponse]);

  function handleInputChange(event) {
    let {
      target: { name, value },
    } = event;

    if (name === "email" || name === "userName") {
      value = value.trim();
    }

    let isValid = true;
    if (name === "firstName" || name === "lastName") {
      const regex = /[^A-Za-z ]/g;
      value = value.replace(regex, "");
      if (
        (name === "firstName" && inputErrors.firstName) ||
        (name === "lastName" && inputErrors.lastName)
      )
        isValid = !!validateNameLength(value);
    } else if (name === "email") {
      if (apiError?.message === "User already exits with email") {
        setApiError("");
      }
      if (inputErrors.email) isValid = !!validateEmail(value);
    } else if (name === "userName") {
      if (apiError?.message === "User already exits with userName") {
        setApiError("");
      }
    } else if (name === "bio" && value.length > 80) {
      return;
    } else if (name === "mobile" && isNaN(value)) {
      value = null;
    } else if (value === "") {
      isValid = false;
    }

    setUser((prevState) => (value === null ? prevState : { ...prevState, [name]: value }));

    if (!(value === null || value === ""))
      setErrors((prevState) => ({
        ...prevState,
        [name]: !isValid,
      }));
  }

  async function uploadImageUsingPresignedURl(uploadResponse) {
    let buf = Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""), "base64");

    const res = await fetch(uploadResponse.getPresignedUploadURL.url, {
      method: "PUT",
      headers: {
        "Content-Type": imageFileDetails.fileType,
      },
      body: buf,
    });
    if (res.status === 200) {
      const key = uploadResponse.getPresignedUploadURL.key;
      user.oldProfilePicUrl = user.profilePicUrl;
      user.profilePicUrl = key;
      handleUpdateProfile();
    } else {
      setIsLoading(false);
      openToast("error", "Failed!", "Try again later");
    }
  }

  function handleSubmitButtonClick(value) {
    if (!value) {
      // reset all changes
      if (userProfile.getUserDetails) {
        setUser(JSON.parse(JSON.stringify(userProfile.getUserDetails.data)));
      } else {
        setUser({});
      }
      if (image) {
        setImage("");
      }
      if (imageFileDetails) {
        setImageFileDetails({});
      }
    } else {
      let errors = {
        email: !user.email || !validateEmail(user.email),
        firstName: !user.firstName || !validateNameLength(user.firstName),
        lastName: !user.lastName || !validateNameLength(user.lastName),
        userName: !user.userName,
      };
      if (Object.values(errors).filter((ele) => ele).length) {
        setErrors((prevState) => ({ ...prevState, ...errors }));
        openToast("error", "Missing Fields", "Please fill the mandatory fields");
      } else {
        if (image) {
          setIsLoading(true);
          Upload({
            variables: {
              file: {
                fileName: imageFileDetails.fileName,
                fileType: imageFileDetails.fileType,
                flag: "PROFILEIMG",
              },
            },
          });
        } else {
          handleUpdateProfile();
        }
      }
    }
  }

  function handleUpdateProfile() {
    delete user.__typename;
    if (userProfile && checkChange(user, userProfile.getUserDetails.data)) {
      if (!isLoading) {
        setIsLoading(true);
      }
      const changes = fetchChanges(user, userProfile.getUserDetails.data);
      updateProfile({
        variables: {
          userDetails: {
            ...changes,
          },
        },
      });
    } else {
      openToast("error", "No change", "changes not detected");
    }
  }

  function setRowImage(file) {
    const parts = file.name.split(".");
    setImageFileDetails({ fileName: file.name, fileType: parts[parts.length - 1] });
  }

  function handleChangePassword() {
    setShowUpdatePassword(!showUpdatePassword);
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={classes.accountHeader} />
      <div className={classes.accountBody}>
        <div className={classes.subHeader}>Profile</div>
        <UserAvatar
          profilePicUrl={user.profilePicUrl}
          image={image}
          setImage={setImage}
          setRowImage={setRowImage}
        />

        <div className={classes.rowWrapper}>
          <InputField
            labelName={"First Name"}
            required
            name="firstName"
            value={user.firstName || ""}
            icon="EditBoldIcon"
            onChange={handleInputChange}
            type="text"
            placeholder="first Name"
            errorText={inputErrors.firstName ? "min 3 characters" : ""}
          />
          <InputField
            labelName={"Last Name"}
            required
            name="lastName"
            value={user.lastName || ""}
            onChange={handleInputChange}
            icon="EditBoldIcon"
            type="text"
            placeholder="Last Name"
            errorText={inputErrors.lastName ? "min 3 characters" : ""}
          />
        </div>
        <InputField
          labelName={"Email"}
          required
          name="email"
          value={user.email || ""}
          onChange={handleInputChange}
          icon="EditBoldIcon"
          type="text"
          placeholder="email"
          errorText={
            inputErrors.email
              ? "Please enter valid email"
              : apiError?.message === "User already exits with email"
              ? "User already exits with provided email"
              : ""
          }
        />
        <InputField
          labelName={"UserName"}
          required
          name="userName"
          value={user.userName || ""}
          onChange={handleInputChange}
          icon="EditBoldIcon"
          type="text"
          placeholder="userName"
          errorText={
            inputErrors.userName
              ? "Please enter username"
              : apiError?.message === "User already exits with userName"
              ? "UserName already exits"
              : ""
          }
        />
        <TextArea
          labelName={"Bio"}
          name="bio"
          value={user.bio || ""}
          onChange={handleInputChange}
          icon="EditBoldIcon"
          placeholder="bio"
          maxLength="80"
        />
        <div className={classes.bioCharLength}>
          <span>{`${user.bio?.length || 0}/80`}</span>
        </div>
        <div className={classes.subHeader}>Personal Information</div>
        <div className={classes.rowWrapper}>
          <InputField
            labelName={"Country"}
            name="country"
            value={user.country || ""}
            onChange={handleInputChange}
            icon="EditBoldIcon"
            type="text"
            placeholder="Country"
          />
          <InputField
            labelName={"State"}
            name="state"
            value={user.state || ""}
            onChange={handleInputChange}
            icon="EditBoldIcon"
            type="text"
            placeholder="State"
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputField
            labelName={"Phone Number"}
            name="mobile"
            value={user.mobile || ""}
            onChange={handleInputChange}
            icon="EditBoldIcon"
            type="text"
            placeholder="Phone Number"
          />
          <InputField
            labelName={"Language"}
            name="language"
            value={user.language || ""}
            onChange={handleInputChange}
            icon="EditBoldIcon"
            type="text"
            placeholder="English"
          />
        </div>

        <div className={classes.passwordUpdateToggle}>
          <div className={classes.changePassword} onClick={handleChangePassword}>
            Change Password
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            buttontype="primary"
            size="default"
            fontFamily="karla"
            onClick={() => handleSubmitButtonClick(true)}
          >
            {"Save"}
          </Button>
          <Button
            buttontype="secondary"
            size="default"
            fontFamily="karla"
            onClick={() => handleSubmitButtonClick(false)}
          >
            {"Cancel"}
          </Button>
        </div>
        {showUpdatePassword && (
          <ModalPortal>
            <PasswordUpdatePopup updatePassword={handleChangePassword} />
          </ModalPortal>
        )}
      </div>
    </>
  );
}
