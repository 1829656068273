import { useRef, useState, useEffect } from "react";
import {
  ButtonContainter,
  CircleSvg,
  MainText,
  ModalContainer,
  Overflow,
  SmallSvgContainer,
} from "../../../components/modal";
import Button from "../../../components/fields/button";
import IconComponent from "../../../components/fields/icon";
import { useStyles } from "./styles";
import CategoriesDropDown from "../../../components/categoriesDropDown";
import { useSelector } from "react-redux";
import Toggle from "../../../components/fields/toggle";
import ModalPortal from "../../../containers/common/modalPortal";
import LinkToItemPopUp from "./linkToItemPopUp";
import {validatePrice , validateTags} from "../../../components/helpers/validate";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function EditClosetItem(props) {
  const options = [
    { value: "Business Casual", label: "Business Casual" },
    { value: "Casual Wear", label: "Casual Wear" },
    { value: "Date Night", label: "Date Night" },
    { value: "Formal Wear", label: "Formal Wear" },
    { value: "Summer Time", label: "Summer Time" },
    { value: "Party Wear", label: "Party Wear" },
  ];
  const classes = useStyles();
  const { modalResponse, item } = props;
  const inputRef = useRef(null);
  const [itemDetails, setItemDetails] = useState(item);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const categoriesList = useSelector((state) => state.category.category);
  const [toggle /* , setToggle */] = useState(false);
  const [image, setImage] = useState("");
  const [openLinkPopUp, setopenLinkPopUp] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(item? item.occasions.split(", ") : []);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleChoiceResult(value) {
    modalResponse(value, itemDetails);
  }
  function handleValueChange(value, type) {
    if (type === "size") {
      if (value.length > 7) {
        return;
      }
      value = value.toUpperCase();
    } else if (type === "tags") {
      if (
        (value.length === 1 && value === " ") ||
        (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) === " ")
      ) {
        return;
      } else if (value.length === 1 && value !== "#") {
        value = "#" + value;
      } else if (value.charAt(value.length - 2) === " " && value.charAt(value.length - 1) !== "#") {
        value = value.substring(0, value.length - 1) + "#" + value.charAt(value.length - 1);
      }

      // to handle when user paste the tags
      if (value.match(/^[^#]|\s\s+|\s+[^#]/gm)) {
        value = validateTags(value.trim());
      }
    }
    setItemDetails({ ...itemDetails, [type]: value });
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpen(event) {
    return setAnchorEl(event.currentTarget);
  }

  function handleSelectImage() {
    inputRef.current.click();
  }

  function handleMenuClick(e) {
    setItemDetails({ ...itemDetails, subCategory: e });
    handleClose();
  }

  function handleToggle() {
    // for now toggle is disabled till sell me flow don't implemented
    // setToggle(!toggle)
  }

  function handleFileUpload(e) {
    const { files } = e.target;
    if (files && files.length) {
      setImage(URL.createObjectURL(files[0]));
      itemDetails.image = files[0];
      setItemDetails({ ...itemDetails });
    }
  }
  function handleLinkModal(linkToItemStore) {
    if (typeof linkToItemStore === "string" && linkToItemStore.includes("http")) {
      handleValueChange(linkToItemStore, "productStoreUrl");
      console.log(linkToItemStore);
    }
    setopenLinkPopUp(!openLinkPopUp);
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((option) => option !== value)
        : [...prevSelected, value],
    );
    let changedSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value];
    handleValueChange(changedSelectedOptions.join(", "), "occasions");
  };

  return (
    <>
      <Overflow>
        <ModalContainer style={{
          minHeight: "0px",
          height: "auto",
          overflowY: "auto",
          scrollbarWidth: "none",
          maxHeight: isTablet ? "75vh":"95vh"
        }}>
          {!toggle && (
            <SmallSvgContainer>
              <CircleSvg>
                <IconComponent iconTitle={"EditLigthIcon"} />
              </CircleSvg>
            </SmallSvgContainer>
          )}

          <MainText>{"Edit"}</MainText>
          <div className={classes.imgContainer} onClick={handleSelectImage}>
            <input
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
              accept="image/*"
            />
            <img
              className={classes.itemImage}
              src={image !== "" ? image : itemDetails.src}
              onError={({ currentTarget }) => (currentTarget.style.display = "none")}
            />
            <div className={classes.uploadLogo}>
              <IconComponent iconTitle={"CameraLinearIcon"} />
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>
              {"Category:"}
              <span>{"*"}</span>
            </div>
            <div className={classes.selectInputWrapper}>
              <CategoriesDropDown
                selectedCategory={itemDetails.subCategory}
                handleClose={handleClose}
                handleOpen={handleOpen}
                categoriesList={categoriesList}
                open={open}
                handleMenuClick={handleMenuClick}
              />
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>
              {"Style Type: (Multi-Select) "}<span>{"*"}</span>
            </div>
            <div className="multi-select-options multi-select-dropdown">
              <div
                style={{
                  height: "auto",
                  marginBottom: "0px",
                  padding: selectedOptions.length ? "10px 8px" : "10px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  position: "relative",
                  flexWrap: "wrap",
                }}
                className={
                  "selected-options " + `${classes.inputWrapper} ${classes.validInputWrapper}`
                }
                onClick={toggleDropdown}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                  }}
                >
                  {selectedOptions.slice(0, isMobile ? 2 : 3).map((option) => (
                    <span
                      key={option}
                      className="selected-option"
                      style={{ whiteSpace: "normal", padding: "0px 2px", fontSize: isMobile ? "14px" : "16px" }}
                    >
                      {options.find((opt) => opt.value === option).label}
                    </span>
                  ))}
                  {selectedOptions.length > (isMobile ? 2 : 3) && (
                    <span
                      className="selected-option"
                      style={{ whiteSpace: "normal", padding: "0px", fontSize: isMobile ? "14px" : "16px"}}
                    >
                      {`..+${selectedOptions.length - (isMobile ? 2 : 3)}`}
                    </span>
                  )}
                </div>
                <span className={classes.dropDownArrow}>
                  {isOpen ? <ArrowDropUpIcon color="action" /> : <ArrowDropDownIcon color="action" />}
                </span>
              </div>
              {isOpen && (
                <div className={`multi-select-option-group ${classes.dropDownOptions}`}>
                  {options.map((option) => (
                    <label key={option.value} className="multi-select-option">
                      <input
                        type="checkbox"
                        id={option.value}
                        value={option.value}
                        checked={selectedOptions.includes(option.value)}
                        onChange={handleChange}
                        className="custom-checkbox"
                        style={{
                          minWidth: isMobile ? "17.5px" : "auto", // Set a minimum width for mobile
                        }}
                      />
                      <label htmlFor={option.value}>{option.label}</label>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={classes.inputsParents} style={{ marginTop: "15px" }}>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Product name:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.itemName}
                  onChange={(e) => handleValueChange(e.target.value, "itemName")}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Brand name:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.brand}
                  onChange={(e) => handleValueChange(e.target.value, "brand")}
                />
              </div>
            </div>
          </div>

          <div className={classes.inputsParents}>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Color:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.color}
                  onChange={(e) => handleValueChange(e.target.value, "color")}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>{"Size:"}</div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.size}
                  onChange={(e) => handleValueChange(e.target.value, "size")}
                />
              </div>
            </div>
          </div>

          <div className={classes.inputsParents}>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                {"Price: "}
              </div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.price}
                  onChange={(e) => handleValueChange(e.target.value, "price")}
                />
              </div>
            </div>
            <div className={classes.inputContainer} style={{visibility: "hidden"}}>
              <div className={classes.inputLabel}>
                {"Price: "}
              </div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.price}
                  onChange={(e) => handleValueChange(e.target.value, "price")}
                />
              </div>
            </div> 
          </div>

          <div className={classes.inputsParents}>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                {"Tags: "}
              </div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  placeholder="#tag_name1 #tag_name2"
                  value={itemDetails.tags}
                  onChange={(e) => handleValueChange(e.target.value, "tags")}
                />
              </div>
            </div>
          </div>

          {itemDetails?.productStoreUrl ? (
            <div className={classes.toggleContainer}>
              <a
                href={itemDetails.productStoreUrl}
                target="_blank"
                rel="noreferrer"
                className={classes.anchorLink}
              >
                {"Link To Item"}
              </a>
            </div>
          ) : (
            <div className={classes.toggleContainer} onClick={handleLinkModal}>
              <div className={classes.anchorLink} style={{ cursor: "pointer" }}>
                Add Link To Item
              </div>
            </div>
          )}

          <div className={classes.toggleContainer}>
            <div className={classes.inputLabel}>{"Sell this item:"}</div>
            <Toggle onClick={handleToggle} active={toggle} />
          </div>

          {/* {toggle && (
          <>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                {"Price: "}
                <span>{"*"}</span>
              </div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.color}
                  onChange={(e) => handleValueChange(e.target.value, "price")}
                />
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.inputLabel}>
                {"Description: "}
                <span>{"*"}</span>
              </div>
              <div className={classes.inputWrapper}>
                <input
                  className={classes.textInput}
                  type="text"
                  value={itemDetails.size}
                  onChange={(e) => handleValueChange(e.target.value, "description")}
                />
              </div>
            </div>
          </>
        )} */}

          <ButtonContainter style={{ marginTop: "0px" }}>
            <Button buttontype="primary" size="default" onClick={() => handleChoiceResult(true)}>
              {"Save"}
            </Button>
            <Button buttontype="secondary" size="default" onClick={() => handleChoiceResult(false)}>
              {"Cancel"}
            </Button>
          </ButtonContainter>
        </ModalContainer>
      </Overflow>
      {openLinkPopUp && (
        <ModalPortal>
          <LinkToItemPopUp modalResponse={handleLinkModal} />
        </ModalPortal>
      )}
    </>
  );
}
