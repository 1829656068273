import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const modalRootEl = document.getElementById("modal-root");

export default function ModalPortal({ children }) {
  const elRef = useRef(document.createElement("div"));

  useEffect(() => {
    modalRootEl.appendChild(elRef.current);
    return () => modalRootEl.removeChild(elRef.current);
  }, []);

  return createPortal(children, elRef.current);
}
