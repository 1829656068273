import { useStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar } from "@mui/material";
import { mobileMaxWidth } from "../../../components/helpers/ResponsiveStyleHelper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getFollowersLists,
  unFollowCreators,
  sendFollowRequest,
  getFollowersListsbyUsername,
} from "../../../apollo/operations/followRequest";
import { useLazyQuery, useMutation } from "@apollo/client";
import ModalPortal from "../../../containers/common/modalPortal";
import Modal from "../../../containers/common/modalPortal/modal";

export default function Followers() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { username } = useParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showUnfollowPopup, setShowUnfollowPopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // Holds the currently interacted user for the modal

  const [getFollowers, { data: followersLists }] = useLazyQuery(getFollowersLists);

  const [getFollowersByUser, { data: followersListsByUser }] = useLazyQuery(
    getFollowersListsbyUsername,
  );

  const [unFollowRequestMutation] = useMutation(unFollowCreators, {
    onCompleted: () => {
      if (username) {
        getFollowersByUser({ variables: { userName: username } });
      } else {
        getFollowers();
      }
    },
  });
  const [sendFollowRequestMutation] = useMutation(sendFollowRequest, {
    onCompleted: () => {
      if (username) {
        getFollowersByUser({ variables: { userName: username } });
      } else {
        getFollowers();
      }
    },
  });

  const followersList = username
    ? followersListsByUser?.getFollowersLists?.data
    : followersLists?.getFollowersLists?.data;

  useEffect(() => {
    if (username) {
      getFollowersByUser({ variables: { userName: username } });
    } else {
      getFollowers();
    }
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleUnFollowClick = (shouldUnfollow, userId) => {
    if (shouldUnfollow && userId) {
      unFollowRequestMutation({
        variables: { receiverId: userId },
      });
    }
    setShowUnfollowPopup(false);
  };

  const onFollowingStatus = (status, item) => {
    if (status === "ACCEPTED") {
      setCurrentUser(item);
      setShowUnfollowPopup(true);
    } else {
      handleUnFollowClick(true, item?.id);
    }
  };

  const handleFollowClick = (receiverId) => {
    sendFollowRequestMutation({
      variables: { receiverId },
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleOne}>
        <ArrowBackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} /> {"Followers"}
      </div>
      <div style={{ paddingTop: 25 }}>
        {followersList?.map((item, i) => (
          <div key={i} className={classes.followingItem}>
            <div className={classes.avatarWrapper}>
              <Avatar
                alt={item.username}
                src={item?.profileUrl}
                sx={{
                  width: windowWidth <= mobileMaxWidth ? 36 : 55,
                  height: windowWidth <= mobileMaxWidth ? 36 : 55,
                }}
                onClick={() => {
                  if (item.id === item.myUserId) {
                    navigate("/followme");
                  } else {
                    navigate(`/followme/profile/${item?.username}`);
                  }
                }}
              />
            </div>
            <div className={classes.textWrapper}>
              <p className={classes.followingName}>{item?.name}</p>
              <p className={classes.followingUsername}>{item?.username}</p>
            </div>
            <div className={classes.buttonWrapper}>
              {item.id !== item.myUserId && (
                <button
                  className={classes.Button}
                  style={{ backgroundColor: item?.followStatus ? "#363434" : "#335b46" }}
                  onClick={() => {
                    if (item?.followStatus) {
                      onFollowingStatus(item?.followStatus, item);
                    } else {
                      handleFollowClick(item?.id);
                    }
                  }}
                >
                  {item?.followStatus === "ACCEPTED"
                    ? "Following"
                    : item?.followStatus === "PENDING"
                    ? "Requested"
                    : username && item?.followingStatus
                    ? "Follow Back"
                    : username
                    ? "Follow"
                    : "Follow Back"}
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      {showUnfollowPopup && currentUser && (
        <ModalPortal>
          <Modal
            type="unFollowCreator"
            handleButtonClick={(value) => handleUnFollowClick(value, currentUser?.id)}
            svgType="circle"
          />
        </ModalPortal>
      )}
    </div>
  );
}
