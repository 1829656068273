import { makeStyles } from "@material-ui/styles";
import {
  smallTabMediaStyle,
  tabMediaStyle,
} from "../../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  parentContainer: {
    padding: "40px 60px",
  },
  title: {
    fontFamily: "var(--primaryHeaderFont)",
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "40px",
  },
  mainContainer: {
    display: "flex",
  },
  rightContainer: {
    width: "85%",
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    borderBottom: "1px solid #D9D9D9",
    height: "100px",
    paddingBottom: "15px",
    boxSizing: "border-box",
    alignItems: "end",
  },
  Button: {
    height: "56px",
    width: "137px",
    fontFamily: "var(--primaryRegularFont)",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "23px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "none",
    letterSpacing: "0.03em",
  },
  enabledShareMe: {
    color: "#ffffff",
    backgroundColor: "#000000",
  },
  disabledShareMe: {
    color: "#ffffff",
    backgroundColor: "#CACACA",
    marginRight: "18px",
  },
  enabledStyleMe: {
    color: "#000000",
    backgroundColor: "#ffffff",
    border: "2px solid #000000",
    boxSizing: "border-box",
    marginLeft: "18px",
  },
  disabledStyleMe: {
    color: "#CACACA",
    backgroundColor: "#ffffff",
    border: "2px solid #CACACA",
    boxSizing: "border-box",
  },
  rightHeader: {
    display: "flex",
    paddingTop: "40px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
  },
  itemsCount: {
    fontWeight: "bold",
  },
  sortOption: {
    marginLeft: "auto",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    position: "relative",
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20vh 0",
    fontFamily: "var(--primaryRegularFont)",
  },
  errorMsg: {
    paddingTop: "5vh",
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "500",
    color: "#CACACA",
  },
  addItemButton: {
    height: "64px",
    width: "220px",
    marginTop: "5vh",
    backgroundColor: "#000000",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    boxSizing: "border-box",
    padding: "0 35px",
    cursor: "pointer",
  },
  closetItemsContainer: {
    paddingTop: "24px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
    rowGap: "30px",
    columnGap: "20px",
  },
  closetItem: {
    fontFamily: "var(--primaryRegularFont)",
    position: "relative",
  },
  actionField: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    width: "32px",
    borderRadius: "16px",
    position: "absolute",
    "& > svg": {
      height: "20px",
      width: "20px",
    },
  },
  selectField: {
    left: "6px",
    top: "6px",
  },
  likeField: {
    top: "6px",
    right: "6px",
  },
  itemImg: {
    width: "100%",
    height: "280px",
    objectFit: "contain",
    boxShadow: "0 0 2px #cdcdcd",
    boxSizing: "border-box",
  },
  itemDetails: {
    width: "100%",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "15px",
    boxSizing: "border-box",
    padding: "0 20px",
    textAlign: "center",
    "& > span": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: "block",
      width: "100%",
    },
  },
  ItemsCard: {
    position: "absolute",
    left: "90%",
    "& > i": {
      filter: "invert(1)",
    },
  },

  //css of left categories
  leftContainer: {
    width: "18%",
    minWidth: "300px",
    paddingRight: "4vw",
    boxSizing: "border-box",
    fontFamily: "var(--primaryRegularFont)",
  },
  leftHeader: {
    height: "100px",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    paddingBottom: "15px",
    display: "flex",
    alignItems: "end",
    borderBottom: "1px solid #D9D9D9",
    boxSizing: "border-box",
  },
  subCategoryMenu: {
    paddingTop: "40px",
    marginBottom: "20px",
  },
  subCategory: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "300",
    marginBottom: "12px",
    cursor: "pointer",
    display: "flex",
    "&:hover": {
      backgroundColor: "#dadada",
      fontWeight: "bold",
    },
  },
  subCategoryArrow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "40px",
  },
  active: {
    fontWeight: "bold !important",
    textDecorationLine: "underline",
  },
  nestedSubCategory: {
    fontSize: "16px",
    fontWeight: "300",
    marginBottom: "18px",
    cursor: "pointer",
    paddingLeft: "40px",
    display: "none",
  },
  nestedActive: {
    display: "block",
  },
  addCategory: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",
    paddingTop: "20px",
    cursor: "pointer",
    display: "flex",
  },
  addCategoryText: {
    paddingLeft: "20px",
  },

  // css for update category popup modal
  buttonsGroup: {
    width: "30%",
    display: "flex",
    justifyContent: "end",
  },
  iconButton: {
    width: "33%",
    boxSizing: "border-box",
    paddingRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    cursor: "pointer",
  },
  categoryRow: {
    boxSizing: "border-box",
    display: "flex",
    height: "50px",
    padding: "5px 0",
    borderBottom: "1px solid #cacaca",
  },
  inputField: {
    width: "70%",
    border: "none",
    fontSize: "18px",
    paddingLeft: "10px",
    fontFamily: "var(--primaryRegularFont)",
    outline: "none",
  },
  label: {
    width: "70%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "18px",
  },
  deactivatedLabel: {
    color: "#cacaca",
  },
  activeLabel: {
    color: "#000000",
  },
  editActive: {
    border: "1px solid #cacaca",
    boxShadow: "0 0 4px #000000",
    borderRadius: "10px",
  },

  // css of editClosetItem popup
  imgContainer: {
    height: "100px",
    width: "100px",
    position: "relative",
    marginBottom: "20px",
  },
  itemImage: {
    height: "100px",
    width: "100px",
    objectFit: "contain",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadLogo: {
    position: "absolute",
    height: "100px",
    width: "100px",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "& > i > svg": {
      width: "20px",
      height: "20px",
    },
  },
  inputContainer: {
    width: "100%",
  },
  inputLabel: {
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    marginBottom: "8px",
    "& > span": {
      color: "#ff0000",
      fontWeight: "bold",
    },
    "& > div > span": {
      color: "#ff0000",
      fontWeight: "bold",
    },
  },
  inputWrapper: {
    height: "54px",
    width: "100%",
    border: "1px solid #000000",
    borderRadius: "10px",
    marginBottom: "16px",
    boxSizing: "border-box",
  },
  textInput: {
    border: "none",
    outline: "none",
    width: "100%",
    height: "100%",
    fontSize: "16px",
    fontFamily: "var(--primaryRegularFont)",
    padding: "0 10px 0 18px",
    borderRadius: "10px",
    boxSizing: "border-box",
  },
  selectInputWrapper: {
    height: "54px",
    width: "100%",
    border: "1px solid #000000",
    marginBottom: "16px",
    boxSizing: "border-box",
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-formControl": {
      fontSize: "16px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 18px !important",
    },
  },
  inputsParents: {
    display: "flex",
    columnGap: "16px",
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  anchorLink: {
    color: "#000000",
    fontFamily: "var(--primaryRegularFont)",
    fontSize: "16px",
    marginBottom: "12px",
  },
  mobileAction: {
    display: "none",
  },
  desktopAction: {
    display: "flex",
  },
  updateCategoryIcons: {
    width: "24px",
    height: "24px",
  },
  imgCont: {
    cursor: "pointer",
  },
  dropDownOptions: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
  },

  ...tabMediaStyle({
    parentContainer: {
      padding: "30px 40px",
    },
    title: {
      fontSize: "30px",
      lineHeight: "32px",
    },
    Button: {
      height: "54px",
    },
    leftHeader: {
      height: "75px",
    },
    buttonContainer: {
      height: "75px",
    },
    leftContainer: {
      minWidth: "220px",
      paddingRight: "24px",
    },
    addCategoryText: {
      paddingLeft: "12px",
    },
    rightHeader: {
      paddingTop: "24px",
    },
    subCategoryMenu: {
      paddingTop: "24px",
    },
    closetItemsContainer: {
      gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
      rowGap: "24px",
      columnGap: "16px",
    },
    itemImg: {
      height: "240px",
    },
    textInput: {
      padding: "0 10px 0 15px",
    },
  }),

  ...smallTabMediaStyle({
    leftContainer: {
      display: "none",
    },
    title: {
      display: "none",
    },
    parentContainer: {
      padding: "24px 0",
    },
    mainContainer: {
      flexDirection: "column",
    },
    rightContainer: {
      width: "unset",
      margin: "0 16px",
    },
    buttonContainer: {
      border: "none",
      padding: "16px 0 0",
      height: "unset",
      justifyContent: "start",
    },
    mobileAction: {
      display: "flex",
    },
    desktopAction: {
      display: "none",
    },
    rightHeader: {
      paddingTop: "12px",
      fontSize: "10px",
      letterSpacing: "0.03em",
    },
    closetItemsContainer: {
      paddingTop: "16px",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(111px, 1fr))",
      gap: "12px",
    },
    Button: {
      height: "36px",
      width: "98px",
      lineHeight: "unset",
      fontSize: "14px",
      letterSpacing: "0.03em",
      borderWidth: "1px",
    },
    itemDetails: {
      lineHeight: "unset",
      fontSize: "10px",
      marginTop: "12px",
      padding: "0 12px",
      letterSpacing: "0.03em",
    },
    ItemsCard: {
      right: 0,
      left: "unset",
      top: 0,
      "& > i": {
        width: "12px",
        height: "12px",
      },
    },
    closetItem: {
      margin: 0,
      width: "100%",
      height: "fit-content",
    },
    imgCont: {
      width: "100%",
      height: "125px",
    },
    itemImg: {
      width: "100%",
      height: "inherit",
    },
    selectField: {
      top: "5px",
      left: "5px",
    },
    likeField: {
      top: "5px",
      right: "5px",
    },
    actionField: {
      width: "20px",
      height: "20px",
      "& > svg": {
        width: "14px",
        height: "14px",
      },
    },
    addCategorySvgCont: {
      paddingBottom: "16px",
    },
    label: {
      fontSize: "14px",
    },
    inputField: {
      fontSize: "14px",
    },
    updateCategoryIcons: {
      width: "18px",
      height: "18px",
    },
    itemsContainer: {
      padding: "5vh 0 3vh",
      "& > i > svg": {
        height: "80px",
        width: "80px",
      },
    },
    errorMsg: {
      paddingTop: "2vh",
      fontSize: "18px",
      lineHeight: "24px",
    },

    addItemButton: {
      height: "56px",
      width: "180px",
      marginTop: "5vh",
      padding: "0 20px",
    },
    inputsParents: {
      columnGap: "8px",
    },
    inputWrapper: {
      height: "48px",
      marginBottom: "12px",
    },
    textInput: {
      fontSize: "14px",
      padding: "0 10px 0 12px",
    },
    inputLabel: {
      fontSize: "14px",
      marginBottom: "5px",
    },
    selectInputWrapper: {
      height: "48px",
      marginBottom: "12px",

      "& .MuiInputBase-formControl": {
        fontSize: "14px !important",
        fontWeight: "400 !important",
      },

      "& .MuiOutlinedInput-input": {
        padding: "14.5px 12px !important",
      },
    },
    dropDownOptions: {
      fontSize: "14px",
    }
  }),
});
