import globalStyles from "../../styles/global";
const { mediaQueries, colors } = globalStyles;

const mobileWidth = mediaQueries.mobile.split(" ")[1];
const smallTabWidth = mediaQueries.smallTablet.split(" ")[1];
const tabWidth = mediaQueries.tablet.split(" ")[1];

const mobileMaxWidth = +mobileWidth.substring(0, mobileWidth.length - 2);
const smallTabMaxWidth = +smallTabWidth.substring(0, smallTabWidth.length - 2);
const tabMaxWidth = +tabWidth.substring(0, mobileWidth.length - 2);

const mobileMediaQuery = `@media (${mediaQueries.mobile})`;
const smallTabMediaQuery = `@media (${mediaQueries.smallTablet})`;
const tabMediaQuery = `@media (${mediaQueries.tablet})`;
const largeTabMediaQuery = `@media (${mediaQueries.largeTablet})`;
const smallMobileMediaQuery = `@media (${mediaQueries.smallMobile})`;
const largerThanTabletMediaQuery = `@media (${mediaQueries.largerThanTablet})`;
const ipadScreenMediaQuery = `@media (${mediaQueries.ipadScreen})`;
const smallIpadScreenMediaQuery = `@media (${mediaQueries.smallIpadScreen})`;
const largerMobileMediaQuery = `@media (${mediaQueries.largerMobile})`;
const smallMobileScreenMediaQuery = `@media (${mediaQueries.smallMobileScreen})`;

function mobileMediaStyle(styleObject = {}, mediaQuery = false) {
  let newObj = {};
  if (!mediaQuery) {
    newObj[mobileMediaQuery] = styleObject;
  } else {
    newObj[mediaQuery] = styleObject;
  }
  return newObj;
}

function smallTabMediaStyle(value) {
  let newObj = {};
  newObj[smallTabMediaQuery] = value;
  return newObj;
}

function tabMediaStyle(value) {
  let newObj = {};
  newObj[tabMediaQuery] = value;
  return newObj;
}

function largeTabletMediaStyle(value) {
  let newObj = {};
  newObj[largeTabMediaQuery] = value;
  return newObj;
}

function largerThanTabletMediaStyle(value) {
  let newObj = {};
  newObj[largerThanTabletMediaQuery] = value;
  return newObj;
}

function smallMobileMediaStyle(value) {
  let newObj = {};
  newObj[smallMobileMediaQuery] = value;
  return newObj;
}

function ipadScreenMediaStyle(styleObject = {}, mediaQuery = false) {
  let newObj = {};
  if (!mediaQuery) {
    newObj[ipadScreenMediaQuery] = styleObject;
  } else {
    newObj[mediaQuery] = styleObject;
  }
  return newObj;
}

function smallIpadScreenMediaStyle(styleObject = {}, mediaQuery = false) {
  let newObj = {};
  if (!mediaQuery) {
    newObj[smallIpadScreenMediaQuery] = styleObject;
  } else {
    newObj[mediaQuery] = styleObject;
  }
  return newObj;
}
function largerMobileMediaStyle(styleObject = {}, mediaQuery = false) {
  let newObj = {};
  if (!mediaQuery) {
    newObj[largerMobileMediaQuery] = styleObject;
  } else {
    newObj[mediaQuery] = styleObject;
  }
  return newObj;
}
function smallMobileScreenMediaStyle(styleObject = {}, mediaQuery = false) {
  let newObj = {};
  if (!mediaQuery) {
    newObj[smallMobileScreenMediaQuery] = styleObject;
  } else {
    newObj[mediaQuery] = styleObject;
  }
  return newObj;
}

export {
  tabMediaStyle,
  smallTabMediaStyle,
  mobileMediaStyle,
  largeTabletMediaStyle,
  largerThanTabletMediaStyle,
  smallMobileMediaStyle,
  ipadScreenMediaStyle,
  smallIpadScreenMediaStyle,
  largerMobileMediaStyle,
  smallMobileScreenMediaStyle,
  mobileMaxWidth,
  smallTabMaxWidth,
  tabMaxWidth,
  mediaQueries,
  colors,
};
