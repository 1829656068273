import { useStyles } from "./styles";
import { memo } from "react";
import CurationBoxes from "../curationBoxes/curationBox";

function ProfileCurationLists(props) {
  const { outfits, index } = props;

  const classes = useStyles();

  return (
    <div className={classes.curationDetails}>
      <div className={classes.CurationsListContainer} id={index}>
        <div className={classes.CurationsListImages}>
          {outfits?.curationList?.length > 0 && <CurationBoxes curations={outfits?.curationList} />}
          {outfits?.itemList?.src && (
            <div className={classes.itemContainer}>
              <img
                className={classes.itemImg}
                draggable={false}
                src={outfits.itemList?.src}
                key={`src-${index}`}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.itemDetails}>
        {outfits?.curationName
          ? outfits?.curationName
          : outfits?.itemName
          ? outfits?.itemName
          : outfits?.itemList?.itemName}
      </div>
    </div>
  );
}

export default memo(ProfileCurationLists);
