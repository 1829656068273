import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import {
  Section,
  Container,
  BackContainer,
  SubContainer,
  ImgContainer,
  SubSection,
} from "../../containers/signin";
import { H2, Hyper, InputField, SubmitButton, Para, Title } from "../../components/signin";
// import background from "../../assets/images/signInImg.png";
import background from "../../assets/images/signInImg_new.jpg";
import { useAuth } from "../../hooks/auth";
import { signInUser } from "../../apollo/operations/user";
import { openToast } from "../../components/toast";
import { setShowPopup, setShowWelcomePopup } from "../../store/showPopupSlice";
import { clearCategory } from "../../store/categorySlice";
import {
  clearCuration,
  clearActiveCurationLook,
  clearStyleMeCuration,
} from "../../store/curationSlice";
import { clearScannedEmail } from "../../store/scannedEmailSlice";
import { useDispatch } from "react-redux";
import Loader from "../../components/loaderScreen";
import { clearScanPeriod } from "../../store/scanPeriodSlice";

export default function SignIn() {
  const [loginDetails, setLoginDetails] = useState({ email: "", password: "", show: false });
  const [inputErrors, setErrors] = useState({});
  const [signIn, { data: signInData, loading }] = useMutation(signInUser);
  const [apiError, setApiError] = useState({});
  const { login, logout } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    logout();
    // on logout wana clear all redux data
    console.info("LOGIN PAGE:FRONTEND IS WORKING")
    dispatch(clearCategory());
    dispatch(clearCuration());
    dispatch(clearStyleMeCuration());
    dispatch(clearActiveCurationLook());
    dispatch(clearScannedEmail());
    dispatch(clearScanPeriod());
  }, []);

  useEffect(() => {
    if (signInData && signInData.signinUser) {
      if (signInData.signinUser.statusCode !== "200") {
        setApiError(signInData.signinUser);
      } else if (signInData.signinUser.data.token) {
        login(signInData.signinUser.data.token);
        dispatch(setShowPopup(true));
        dispatch(setShowWelcomePopup(signInData.signinUser.data.showWelcomePopup));
        openToast("success", "Welcome Back!", "Login successfully");
      }
    }
  }, [signInData]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    let isValid = true;
    if (value === "") {
      isValid = false;
    }
    setLoginDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({ ...prevState, [name]: !isValid }));
    if (apiError?.message) {
      setApiError({});
    }
  };

  const handleIconClick = () => {
    handleChange({ target: { name: "show", value: !loginDetails.show } });
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let errors = {
      email: !loginDetails.email,
      password: !loginDetails.password,
    };
    if (Object.values(errors).filter((ele) => ele).length) {
      setErrors((prevState) => ({ ...prevState, ...errors }));
    } else {
      signIn({
        variables: { userSignin: { email: loginDetails.email, password: loginDetails.password } },
      });
    }
  };

  return (
    <>
      <Section>
        <SubSection>
          <ImgContainer img={background} />
          <Container img={background}>
            <BackContainer>
              <SubContainer>
                <Title>MIKLOSET</Title>
                <H2>SIGN IN</H2>
                <InputField
                  autocomplete="email"
                  labelName={"Email/Username"}
                  name="email"
                  value={loginDetails.email}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  type="email"
                  placeholder="Enter your Email or UserName"
                  errorText={
                    inputErrors.email
                      ? "Please enter a Email or UserName"
                      : apiError?.message === "Invalid Email"
                      ? "no account created"
                      : ""
                  }
                />
                <InputField
                  autocomplete="Password"
                  labelName={"Password"}
                  name="password"
                  value={loginDetails.password}
                  type={loginDetails.show ? "text" : "password"}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  icon={loginDetails.show ? "EyeBoldCrossIcon" : "EyeBoldIcon"}
                  onIconClick={handleIconClick}
                  placeholder="Enter the password"
                  errorText={
                    inputErrors.password
                      ? "Please enter a Password"
                      : apiError?.message === "Invalid Credentials"
                      ? "Password is not Valid"
                      : ""
                  }
                />
                <Hyper underline="true" to={"/forgotpassword"}>
                  Forgot Password?
                </Hyper>
                <SubmitButton
                  buttontype="primary"
                  onClick={handleSubmit}
                  disabled={Object.values(inputErrors).filter((ele) => ele === true).length}
                >
                  Login
                </SubmitButton>
                <Para>
                  {/* Don’t have an account? <Hyper to={"/signup"}>Sign Up</Hyper> */}
                  Don’t have an account? <Hyper to={"/betatesters/signup"}>Sign Up</Hyper>
                </Para>
              </SubContainer>
            </BackContainer>
          </Container>
        </SubSection>
      </Section>
      {loading && <Loader />}
    </>
  );
}
