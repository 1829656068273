import { useState, useEffect } from "react";
import IconComponent from "../../../components/fields/icon";
import { useStyles } from "./styles";
import { CardOptions } from "../../../containers/dashboard";
import ModalPortal from "../../../containers/common/modalPortal";
import Modal from "../../../containers/common/modalPortal/modal";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  uploadImage,
  removeItemFromCloset,
  updateItemDetails,
} from "../../../apollo/operations/items";
import { openToast } from "../../../components/toast";
import Loader from "../../../components/loaderScreen";
import EditClosetItem from "./editClosetItem";
import { useDispatch, useSelector } from "react-redux";
import {
  setCuration,
  setBulkCuration,
  setFlags,
  deleteCuration,
} from "../../../store/curationSlice";
import { checkChange, fetchChanges } from "../../../components/helpers/productHelper";
import { Tooltip } from "@mui/material";
// import { getStyleMe } from "../../../apollo/operations/styleme";
import useGetStyleMe from "../../../hooks/useGetStyleMe";
import { addStyleMe } from "../../../apollo/operations/styleme";

export default function ClosetItems(props) {
  const { SelectedList, loading: styleMeloading } = useGetStyleMe();
  const [updateStyleMeDB, { data: updateStyleMeDBData, loading: updateStyleMeDBLoading }] =
    useMutation(addStyleMe);
  const classes = useStyles();
  const { items, setItems, fetchItemsAPI, likedItem, dragStarted } = props;
  const [openCard, setOpenCard] = useState(null);
  const dispatch = useDispatch();
  const curationsList = useSelector((state) => state.curation.curation);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [editConfirmationPopup, setEditConfirmationPopup] = useState(false);
  const [removeItem, { data: removeItemApiResponse, loading }] = useMutation(removeItemFromCloset);
  const [upload, { data: uploadResponse }] = useLazyQuery(uploadImage);
  // const { getStyleMeData, error, getStyleMeLoading, refetch } = useQuery(getStyleMe);
  const [updateItem, { data: updateItemApiResponse, loading: updateItemLoading }] =
    useMutation(updateItemDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedItem, setUpdatedItem] = useState(null);
  const [dbSelectedList, setDbSelectedList] = useState([]);

  const handleOnClick = (event) => {
    const cardEle = document.getElementsByClassName("itemEditCard")[0];
    if (cardEle) {
      const popUp = event.target.closest(".itemEditCard");
      !popUp && setOpenCard("");
    }
  };
  useEffect(() => {
    setDbSelectedList(SelectedList);
  }, [styleMeloading]);

  useEffect(() => {
    window.addEventListener("click", handleOnClick);
    return () => window.removeEventListener("click", handleOnClick);
  }, []);

  useEffect(() => {
    if (removeItemApiResponse) {
      if (removeItemApiResponse.removeItemFromCloset?.statusCode === "200") {
        openToast("success", "Removed successfully", "Your item has been removed successfully.");
        fetchItemsAPI();
      } else {
        openToast("error", "Remove failed", "failed to removed your item, try again later.");
      }
    }
  }, [removeItemApiResponse]);

  useEffect(() => {
    if (updateItemApiResponse) {
      if (updateItemApiResponse.updateItemDetails?.statusCode === "200") {
        setIsLoading(false);
        openToast("success", "Updated successfully", "Your item has been updated successfully.");
        fetchItemsAPI();
      } else {
        setIsLoading(false);
        openToast("error", "Update failed", "failed to update your item, try again later.");
      }
    }
  }, [updateItemLoading]);

  useEffect(() => {
    if (uploadResponse && uploadResponse.getPresignedUploadURL) {
      uploadImageUsingPresignedURl(uploadResponse);
    } else if (isLoading) {
      setIsLoading(false);
    }
  }, [uploadResponse]);

  async function uploadImageUsingPresignedURl(uploadResponse) {
    const parts = updatedItem.image.name.split(".");
    const fileType = parts[parts.length - 1];

    const res = await fetch(uploadResponse.getPresignedUploadURL.url, {
      method: "PUT",
      headers: {
        "Content-Type": fileType,
      },
      body: updatedItem.image,
    });
    if (res.status === 200) {
      const key = uploadResponse.getPresignedUploadURL.key;
      updatedItem.key = key;
      handleUpdateItem();
    } else {
      setIsLoading(false);
      openToast("error", "Failed!", "Try again");
    }
  }

  function handleOpenCard(itemName) {
    setOpenCard(itemName);
  }

  function onSelectItem(e, item, type) {
    e.stopPropagation();
    setSelectedItem(item);
    setOpenCard("");

    if (type === "edit") {
      setShowUpdatePopup(true);
      setUpdatedItem(JSON.parse(JSON.stringify(item)));
    } else {
      setShowRemovePopup(true);
    }
  }

  function onRemoveItemResponse(value) {
    if (value) {
      removeItem({ variables: { removeItems: { id: selectedItem.id } } });
    }
    setShowRemovePopup(false);
  }

  function handleEditResponse(value, itemDetails) {
    if (value) {
      setUpdatedItem(itemDetails);
      setEditConfirmationPopup(true);
    }
    setShowUpdatePopup(false);
  }

  function onEditItem(value) {
    if (value) {
      if (updatedItem.image) {
        setIsLoading(true);
        const fileName = updatedItem.image.name;
        const parts = fileName.split(".");
        const fileType = parts[parts.length - 1];
        upload({ variables: { file: { fileName: fileName, fileType: fileType } } });
      } else {
        handleUpdateItem();
      }
    }
    setEditConfirmationPopup(false);
  }

  function handleUpdateItem() {
    delete updatedItem.image;
    if (checkChange(updatedItem, selectedItem)) {
      if (!isLoading) {
        setIsLoading(true);
      }
      const changes = fetchChanges(updatedItem, selectedItem);
      updateItem({
        variables: {
          updateItems: {
            ...changes,
            id: selectedItem.id,
          },
        },
      });
    }
  }

  function handleLikeClick(item, index) {
    likedItem(!item.isLiked, item.id);
    items[index].isLiked = !item.isLiked;
    setItems([...items]);
  }

  function setSelectedState(item) {
    const data = {
      ...item,
      isSelected: curationsList.length
        ? !item.isSelected
        : !dbSelectedList.some((eleItem) => Number(eleItem.id) === Number(item.id)),
    };
    setItems((preVal) => {
      const index = preVal.findIndex((preValItem) => preValItem.id === item.id);
      if (index !== -1) preVal[index] = data;
      return JSON.parse(JSON.stringify(preVal));
    });

    if (curationsList?.length) {
      if (data.isSelected) {
        dispatch(setCuration(data));
        updateStyleMeDB({
          variables: {
            styleMeData: {
              selectList: structuredClone(dbSelectedList.concat(data).map((item) => `${item.id}`)),
              curationList: [],
            },
          },
        })
        dispatch(setFlags({ updateStyleMeDb: true }));
      } else {
        const index = curationsList?.findIndex((curItem) => Number(curItem.id) === Number(data.id));
        dispatch(deleteCuration(index));

        const filterList = dbSelectedList.filter((fitem) => Number(fitem.id) !== Number(item.id));
        setDbSelectedList(filterList);
        updateStyleMeDB({
          variables: {
            styleMeData: {
              selectList: structuredClone(filterList.map((item) => `${item.id}`)),
              curationList: [],
            },
          },
        });
      }
    } else {
      // if user refresh the page this will be in use for empty curation
      if (data.isSelected) {
        dispatch(setBulkCuration(dbSelectedList.concat(data)));
        dispatch(setFlags({ updateStyleMeDb: true }));
      } else {
        const index = dbSelectedList?.findIndex(
          (curItem) => Number(curItem.id) === Number(data.id),
        );
        dispatch(deleteCuration(index));
        const filterList = dbSelectedList.filter((fitem) => Number(fitem.id) !== Number(item.id));
        setDbSelectedList(filterList);

        updateStyleMeDB({
          variables: {
            styleMeData: {
              selectList: structuredClone(filterList.map((item) => `${item.id}`)),
              curationList: [],
            },
          },
        });
      }
    }
  }

  function Item({ item, index }) {
    return (
      <div className={classes.closetItem}>
        <div className={classes.imgCont} draggable onDragStart={() => dragStarted(item)}>
          <img
            className={classes.itemImg}
            src={`${item.src}`}
            onError={({ currentTarget }) => (currentTarget.style.display = "none")}
          />
        </div>
        <IconComponent
          className={`${classes.actionField} ${classes.selectField}`}
          iconTitle={
            item.isSelected ||
            dbSelectedList.some((eleItem) => Number(eleItem.id) === Number(item.id))
              ? "SelectedVector"
              : "UnSelectedVector"
          }
          onClick={(event) => {
            event.stopPropagation();
            setSelectedState(item);
          }}
        />
        <IconComponent
          className={`${classes.actionField} ${classes.likeField}`}
          iconTitle={item.isLiked ? "LikedVector" : "UnLikedVector"}
          onClick={() => handleLikeClick(item, index)}
        />
        <div className={classes.itemDetails}>
          <Tooltip title={item.itemName} placement="top">
            <span>{item.itemName}</span>
          </Tooltip>
          {item?.isSelected}
          <div className={classes.ItemsCard}>
            <IconComponent
              iconTitle={"ThreeDotsIcon"}
              onClick={(event) => {
                event.stopPropagation();
                handleOpenCard(index);
              }}
            />
            {index === openCard ? (
              <CardOptions className="itemEditCard" closetItem={true}>
                <p onClick={(e) => onSelectItem(e, item, "edit")}>Edit</p>
                <p onClick={(e) => onSelectItem(e, item, "remove")}>Remove</p>
              </CardOptions>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.closetItemsContainer}>
      {(loading || isLoading) && <Loader />}
      {items.map((item, index) => (
        <Item item={item} index={index} key={index} />
      ))}
      {showUpdatePopup && (
        <ModalPortal>
          <EditClosetItem modalResponse={handleEditResponse} item={updatedItem} />
        </ModalPortal>
      )}
      {showRemovePopup && (
        <ModalPortal>
          <Modal type={"removeItem"} handleButtonClick={onRemoveItemResponse} svgType="circle" />
        </ModalPortal>
      )}
      {editConfirmationPopup && (
        <ModalPortal>
          <Modal type={"editClosetItem"} handleButtonClick={onEditItem} svgType="circle" />
        </ModalPortal>
      )}
    </div>
  );
}
