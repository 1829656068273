import ActivewearMen from "./Activewear_Men.jpg";
import AccessoriesMen from "./Accessories_Men.jpg";
import BeautyMen from "./Beauty2_Men.jpeg";
import BeachwearMen from "./Beachwear_Men.jpg";
import DenimMen from "./Denim_Men.jpg";
import MiscellaneousMen from "./Misc_Men.jpg";
import OuterwearMen from "./Outerwear_Men.jpg";
import PantsMen from "./Pant_Men.jpg";
import ShoesMen from "./Shoes_Men.jpg";
import ShortsMen from "./shorts_men.png";
import ShirtsMen from "./shirts_men.png";
import SuitsMen from "./Suit_Men.jpg";
import HangerMen from "./hanger_men.png";
import HangerMenNew from "./hanger_men_new.png";

export default {
  AccessoriesMen,
  ActivewearMen,
  BeachwearMen,
  BeautyMen,
  DenimMen,
  HangerMen,
  HangerMenNew,
  MiscellaneousMen,
  OuterwearMen,
  PantsMen,
  ShirtsMen,
  ShoesMen,
  ShortsMen,
  SuitsMen,
};
