import { makeStyles } from "@material-ui/styles";
import { mobileMediaStyle, tabMediaStyle } from "../../components/helpers/ResponsiveStyleHelper";

export const useStyles = makeStyles({
  root: {
    fontFamily: "var(--primaryRegularFont)",
    padding: "30px 60px",
  },
  profile: {
    marginTop: "80px",
    display: "flex",
    gap: 40,
    alignItems: "center",
  },
  profileDetails: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  email: {
    fontSize: 20,
  },
  editBtn: {
    marginTop: 50,
  },
  followersDetails: {
    marginTop: 68,
    display: "flex",
    gap: 40,
  },
  subFollow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    fontSize: 18,
    letterSpacing: 0.72,
    "& h1": {
      fontSize: 20,
    },
    cursor: "pointer",
  },
  following: {
    margin: "12px 0 6px 0",
    fontWeight: 600,
    lineHeight: "36px",
  },
  myItems: {
    margin: "80px 0 10px 0",
    fontWeight: 600,
    lineHeight: "36px",
  },
  about: {
    marginTop: 12,
    lineHeight: "26px",
    whiteSpace: "pre-line",
  },
  followingImages: {
    display: "flex",
    gap: 24,
    cursor: "pointer",
  },
  followingName: {
    textAlign: "center",
    marginTop: 6,
    fontSize: 12,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "72px",
  },
  curationGroupContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 40,
  },
  curationGroupName: {
    fontSize: 12,
    marginTop: 18,
  },
  curationGroup: {
    width: "300px",
    height: "280px",
    display: "grid",
    gridGap: 6,
    gridTemplateColumns: "repeat(auto-fit, minmax(120px, auto))",
  },
  CurationCardContainer: {
    border: "1px solid #000000",
    padding: "5px",
    display: "grid",
    maxHeight: "100%",
    overflow: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      width: "12px",
      height: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "7px",
      backgroundClip: "padding-box",
      border: "4px solid transparent",
    },
  },
  CurationCardImages: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "repeat(auto-fit, minmax(51px, auto))",
    gridAutoFlow: "dense",
    height: "100%",
    overflow: "auto",
    gap: "5px",

    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
  },
  outfitImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "fit-content",
    objectFit: "cover",
  },
  itemsConatiner: {
    display: "flex",
    flexWrap: "wrap",
    gap: 12,
    alignItems: "center",
  },
  modalContainer: {
    fontFamily: "var(--primaryRegularFont)",
    minHeight: "unset",
    width: "611px",
    padding: "24px 35px 64px",
  },
  countClass: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#808080b5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: 16,
  },
  findMoreText: {
    color: "#fff",
    padding: "10px",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "Roboto",
    lineHeight: "25px",
  },
  curationContainer: {
    paddingTop: "24px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
    gap: "30px 15px",
  },
  paggination: {
    padding: "10px 0 10px",
    justifyContent: "right",
    display: "flex",
    fontSize: ".75rem",
    letterSpacing: "normal",
    color: "#5e5e5e",
  },
  arrowLeft: {
    padding: "0px 5px",
    rotate: "90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },
  arrowRight: {
    padding: "0px 5px",
    rotate: "-90deg",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D9D9D9",
    },
  },

  ...tabMediaStyle({
    subFollow: {
      fontSize: 14,
      gap: 6,
      letterSpacing: 0.48,
      "& h1": {
        fontSize: 16,
      },
    },
    paggination: {
      width: "97%",
    },
    root: {
      padding: "24px 36px 24px",
    },
  }),

  ...mobileMediaStyle({
    root: {
      padding: "12px 18px 12px",
    },
    paggination: {
      padding: "12px 0 16px",
      width: "86%",
    },
    profile: {
      gap: 30,
      marginTop: 18,
    },
    profileDetails: {
      gap: 0,
      "& h1": {
        fontSize: 16,
        letterSpacing: 0.48,
      },
    },
    editBtn: {
      marginTop: 12,
      width: 140,
      height: 48,
    },
    email: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.36,
    },
    followersDetails: {
      marginTop: 31,
      gap: 24,
    },
    subFollow: {
      fontSize: 12,
      gap: 6,
      letterSpacing: 0.48,
      "& h1": {
        fontSize: 14,
      },
    },
    about: {
      lineHeight: "18px",
      fontSize: 10,
    },
    following: {
      fontSize: 12,
      lineHeight: "unset",
    },

    myItems: {
      margin: "50px 0 10px 0",
      fontSize: 12,
      lineHeight: "unset",
    },
    followingName: {
      maxWidth: "45px",
      fontSize: 8,
    },
    followingImages: {
      gap: 12,
    },
    curationGroupContainer: {
      gap: "12px 18px",
      "& > div": {
        height: 170,
        width: 170,
      },
    },
    curationGroup: {
      height: "calc(100% - 20px)",
      width: "100%",
      gridGap: 3.5,
      gridTemplateColumns: "repeat(auto-fit, minmax(60px, auto))",
    },
    curationGroupName: {
      fontSize: 10,
      marginTop: 6,
    },
    CurationCardImages: {
      gridTemplateColumns: "repeat(auto-fit, minmax(30px, auto))",
    },
    CurationCardContainer: {
      padding: 3,
    },
    findMoreText: {
      fontSize: "7px",
      lineHeight: "10px",
    },
  }),
});
