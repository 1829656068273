import { useStyles } from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Avatar } from "@mui/material";
import { mobileMaxWidth } from "../../../components/helpers/ResponsiveStyleHelper";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getFollowRequests,
  acceptFollowRequest,
  rejectFollowRequest,
  getfollowBackLists,
  sendFollowRequest,
  getTrendingCreators,
  unFollowCreators,
  getPendingRequest,
} from "../../../apollo/operations/followRequest";
import { useLazyQuery, useMutation } from "@apollo/client";

export default function FollowRequests() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [currentPagePendingRequest, setCurrentPagePendingRequest] = useState(1); // For pagination
  const itemsPerPage = 5;
  const [followedCreators, setFollowedCreators] = useState({});
  const [followBackCreators, setFollowBackCreators] = useState({});

  const [unFollowedCreators, setUnFollowedCreators] = useState({});

  const [getRequests, { data: FollowRequests }] = useLazyQuery(getFollowRequests);
  const [getFollowBacks, { data: FollowBacks }] = useLazyQuery(getfollowBackLists);
  const [getCreators, { data: trendingCreators }] = useLazyQuery(getTrendingCreators);
  let [getPending, { data: pendingRequestList }] = useLazyQuery(getPendingRequest);
  const [unFollowRequestMutation] = useMutation(unFollowCreators);

  useEffect(() => {
    getRequests();
    getFollowBacks();
    getPending();
    getCreators({ variables: { limit: 10 } });
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const followRequest = FollowRequests?.getFollowRequests?.data;
  const followBack = FollowBacks?.getfollowBackLists?.data;
  const trendingCreator = trendingCreators?.getTrendingCreators?.data || [];
  let pendingRequests = pendingRequestList?.getPendingRequest?.data || [];

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const startIndexPendingRequest = (currentPagePendingRequest - 1) * itemsPerPage;
  const endIndexPendingRequest = startIndexPendingRequest + itemsPerPage;

  const followRequestItems = followRequest?.slice(startIndex, endIndex);
  let pendingRequestItems = pendingRequests?.slice(
    startIndexPendingRequest,
    endIndexPendingRequest,
  );

  const totalPages = Math.ceil(followRequest?.length / itemsPerPage);
  const totalPagesPendingRequest = Math.ceil(pendingRequests?.length / itemsPerPage);

  const [acceptFollowRequestMutation] = useMutation(acceptFollowRequest, {
    onCompleted: () => {
      getRequests();
      getFollowBacks();
    },
  });

  const [rejectFollowRequestMutation] = useMutation(rejectFollowRequest, {
    onCompleted: () => {
      getRequests();
    },
  });

  const [sendFollowRequestMutation] = useMutation(sendFollowRequest);

  const handleCreatorsFollowClick = (receiverId) => {
    sendFollowRequestMutation({
      variables: { receiverId: receiverId },
      onCompleted: () => {
        setFollowedCreators((prev) => ({
          ...prev,
          [receiverId]: true,
        }));
      },
    });
  };

  const handleUnfollowClick = (receiverId) => {
    unFollowRequestMutation({
      variables: { receiverId: receiverId },
      onCompleted: () => {
        setFollowedCreators((prev) => {
          const updatedCreators = { ...prev };
          delete updatedCreators[receiverId];
          return updatedCreators;
        });
      },
    });
  };

  const handleFollowBackClick = (receiverId) => {
    sendFollowRequestMutation({
      variables: { receiverId: receiverId },
      onCompleted: () => {
        getFollowBacks();
        getCreators({ variables: { limit: 10 } });
      },
    });
  };

  const handleFollowAcceptClick = (senderId) => {
    acceptFollowRequestMutation({
      variables: { senderId: senderId },
    });
  };

  const handleDeleteFollowClick = (senderId) => {
    rejectFollowRequestMutation({
      variables: { senderId: senderId },
    });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPagePendingRequest = () => {
    if (currentPagePendingRequest < totalPagesPendingRequest) {
      setCurrentPagePendingRequest(currentPagePendingRequest + 1);
    }
  };

  const handlePrevPagePendingRequest = () => {
    if (currentPagePendingRequest > 1) {
      setCurrentPagePendingRequest(currentPagePendingRequest - 1);
    }
  };

  const handleUnFollowClick = (shouldUnfollow, userId) => {
    if (shouldUnfollow && userId) {
      unFollowRequestMutation({
        variables: { receiverId: userId },
      });
    }
  };

  const onFollowingStatus = (item, index) => {
    if (!unFollowedCreators[item?.id]) {
      handleUnFollowClick(true, item?.id);
      setUnFollowedCreators((prev) => {
        const updatedCreators = { ...prev };
        updatedCreators[item?.id] = true;
        return updatedCreators;
      });
    } else {
      handleFollowClick(item.id);
      if (unFollowedCreators[item?.id]) {
        setUnFollowedCreators((prev) => {
          const updatedCreators = { ...prev };
          updatedCreators[item?.id] = false;
          return updatedCreators;
        });
      }
    }
  };

  const handleFollowClick = (receiverId) => {
    sendFollowRequestMutation({
      variables: { receiverId },
    });
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleOne}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", marginRight: "10px" }}
        />
        <div>{"Follow Requests"}</div>
        <div className={classes.requestsCount}>+{followRequest?.length} requests</div>
      </div>

      <div style={{ paddingTop: 25 }}>
        {followRequestItems?.map((item, i) => (
          <div key={i} className={classes.followingItem}>
            <div className={classes.avatarWrapper}>
              <Avatar
                alt={item.username}
                src={item?.profileUrl}
                sx={{
                  width: windowWidth <= mobileMaxWidth ? 36 : 55,
                  height: windowWidth <= mobileMaxWidth ? 36 : 55,
                }}
                onClick={() => navigate(`/followme/profile/${item?.username}`)}
              />
            </div>
            <div className={classes.textWrapper}>
              <p className={classes.followingName}>{item?.name}</p>
              <p className={classes.followingUsername}>{item?.username}</p>
            </div>
            <div className={classes.buttonWrapper}>
              <button
                className={classes.Button}
                style={{ backgroundColor: "#335b46" }}
                onClick={() => handleFollowAcceptClick(item.id)}
              >
                Confirm
              </button>
              <button
                className={classes.Button}
                style={{ backgroundColor: "#363434" }}
                onClick={() => handleDeleteFollowClick(item.id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 20 }}>
        {totalPages > 1 && (
          <>
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              style={{
                background: "none",
                border: "none",
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
              }}
            >
              <ArrowLeftIcon style={{ fontSize: 30, color: currentPage === 1 ? "#ccc" : "#000" }} />
            </button>
            <span
              style={{
                margin: "0 15px",
                fontSize: "16px",
                fontStyle: "italic",
                fontFamily: "Roboto",
              }}
            >
              {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{
                background: "none",
                border: "none",
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              <ArrowRightIcon
                style={{ fontSize: 30, color: currentPage === totalPages ? "#ccc" : "#000" }}
              />
            </button>
          </>
        )}
      </div>

      <div className={classes.titleOne}>People to Follow Back</div>
      <div style={{ paddingTop: 25, paddingBottom: 25 }}>
        {followBack?.map((item, i) => (
          <div key={i} className={classes.followingItem}>
            <div className={classes.avatarWrapper}>
              <Avatar
                alt={item?.name}
                src={item?.profileUrl}
                sx={{
                  width: windowWidth <= mobileMaxWidth ? 36 : 55,
                  height: windowWidth <= mobileMaxWidth ? 36 : 55,
                }}
                onClick={() => navigate(`/followme/profile/${item?.username}`)}
              />
            </div>
            <div className={classes.textWrapper}>
              <p className={classes.followingName}>{item?.name}</p>
              <p className={classes.followingUsername}>{item?.username}</p>
            </div>
            <div className={classes.buttonWrapper}>
              <button
                className={classes.FollowBackButton}
                disabled={followBackCreators[item.id]}
                style={{ backgroundColor: followBackCreators[item.id] ? "#363434" : "#335b46" }}
                onClick={() => handleFollowBackClick(item.id)}
              >
                {followBackCreators[item.id] ? "Requested" : "Follow Back"}
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.titleOne}>Requested to Follow </div>
      <div style={{ paddingTop: 25, paddingBottom: 25 }}>
        {pendingRequestItems?.map((item, i) => (
          <div key={i} className={classes.followingItem}>
            <div className={classes.avatarWrapper}>
              <Avatar
                alt={item?.name}
                src={item?.profileUrl}
                sx={{
                  width: windowWidth <= mobileMaxWidth ? 36 : 55,
                  height: windowWidth <= mobileMaxWidth ? 36 : 55,
                }}
                onClick={() => navigate(`/followme/profile/${item?.username}`)}
              />
            </div>
            <div className={classes.textWrapper}>
              <p className={classes.followingName}>{item?.name}</p>
              <p className={classes.followingUsername}>{item?.username}</p>
            </div>
            <div className={classes.buttonWrapper}>
              <button
                className={classes.FollowBackButton}
                style={{ backgroundColor: unFollowedCreators[item.id] ? "#335b46" : "#363434" }}
                onClick={() => onFollowingStatus(item, i)}
              >
                {unFollowedCreators[item.id] ? "Follow" : "Requested"}
              </button>
            </div>
          </div>
        ))}
      </div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 20 }}>
        {totalPagesPendingRequest > 1 && (
          <>
            <button
              onClick={handlePrevPagePendingRequest}
              disabled={currentPagePendingRequest === 1}
              style={{
                background: "none",
                border: "none",
                cursor: currentPagePendingRequest === 1 ? "not-allowed" : "pointer",
              }}
            >
              <ArrowLeftIcon
                style={{ fontSize: 30, color: currentPagePendingRequest === 1 ? "#ccc" : "#000" }}
              />
            </button>
            <span
              style={{
                margin: "0 15px",
                fontSize: "16px",
                fontStyle: "italic",
                fontFamily: "Roboto",
              }}
            >
              {currentPagePendingRequest} of {totalPagesPendingRequest}
            </span>
            <button
              onClick={handleNextPagePendingRequest}
              disabled={currentPagePendingRequest === totalPagesPendingRequest}
              style={{
                background: "none",
                border: "none",
                cursor:
                  currentPagePendingRequest === totalPagesPendingRequest
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <ArrowRightIcon
                style={{
                  fontSize: 30,
                  color: currentPagePendingRequest === totalPagesPendingRequest ? "#ccc" : "#000",
                }}
              />
            </button>
          </>
        )}
      </div>

      <div className={classes.titleOne} style={{ marginTop: 25 }}>
        Trending Creators
      </div>
      <div style={{ paddingTop: 25 }}>
        {trendingCreator?.map((item, i) => (
          <div key={i} className={classes.followingItem}>
            <div className={classes.avatarWrapper}>
              <Avatar
                alt={item?.name}
                src={item?.profileUrl}
                sx={{
                  width: windowWidth <= mobileMaxWidth ? 36 : 55,
                  height: windowWidth <= mobileMaxWidth ? 36 : 55,
                }}
                onClick={() => navigate(`/followme/profile/${item?.username}`)}
              />
            </div>
            <div className={classes.textWrapper}>
              <p className={classes.followingName}>{item?.name}</p>
              <p className={classes.followingUsername}>{item?.username}</p>
            </div>
            <div className={classes.buttonWrapper}>
              <button
                className={classes.Button}
                onClick={() => {
                  if (followedCreators[item.id]) {
                    handleUnfollowClick(item.id);
                  } else {
                    handleCreatorsFollowClick(item.id);
                  }
                }}
                style={{ backgroundColor: followedCreators[item.id] ? "#363434" : "#335b46" }}
              >
                {followedCreators[item.id] ? "Requested" : item?.action || "Follow"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
