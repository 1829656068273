import { createBrowserRouter } from "react-router-dom";

import {
  Landing,
  SignIn,
  SignUp,
  ForgotPassword,
  ResetPassword,
  Dashboard,
  AddYourStyle,
  ScanEmail,
  AddNewItem,
  Category,
  UploadFile,
  Notifications,
  Settings,
  Account,
  HiddenCategory,
  StyleMe,
  CurateMe,
  Favorites,
  Receipts,
  AboutUs,
  ContactUs,
  FAQ,
  Followers,
  Creators,
  FollowRequests,
  Followings,
  CreatorProfile,
} from "../pages/index";

import ProtectedLayout from "../containers/common/App";
import { AuthLayout } from "../hooks/auth";
import NotificationSetting from "../pages/settings/notificationSetting";
import ScanEmails from "../pages/settings/scanEmail";
import Profile from "../pages/profile";
import Waitlist from "../pages/WaitList";
import WaitListJoined from "../pages/WaitList/WaitlistJoined";
import AdminSignIn from "../pages/Admin/AdminSignIn";
import AdminPage from "../pages/Admin";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const routes = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Landing landingType="" />,
      },
      {
        path: "/",
        element: <Landing landingType="" />,
      },
      {
        path: "/admin",
        element: <AdminSignIn pageType="adminSign" />,
      },
      {
        path: "/admin/dashboard",
        element: <AdminPage />,
      },
      {
        path: "/betatesters",
        element: <Landing landingType="beta" />,
      },
      {
        path: "/waitlist",
        element: <Waitlist />,
      },
      {
        path: "/confirmed",
        element: <WaitListJoined pageType="confirmed" />,
      },
      // {
      //   path: "/signin",
      //   element: <SignIn />,
      // },
      // {
      //   path: "/signup",
      //   element: <SignUp />,
      // },
      {
        path: "/betatesters/signin",
        element: <SignIn />,
      },
      {
        path: "/betatesters/signup",
        element: <SignUp />,
      },
      {
        path: "/forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "/resetpassword/:token",
        element: <ResetPassword />,
      },
      {
        path: "/privacy_policy",
        element: <PrivacyPolicy />
      },
      {
        element: <ProtectedLayout />,
        children: [
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/dashboard/aboutus",
            element: <AboutUs />,
          },
          {
            path: "/dashboard/contactus",
            element: <ContactUs path="/dashboard/contactus" />,
          },
          {
            path: "/dashboard/faq",
            element: <FAQ path="/dashboard/faq" />,
          },
          {
            path: "/dashboard/category/:category",
            element: <Category />,
          },
          {
            path: "/addyourstyle",
            element: <AddYourStyle />,
            children: [
              {
                path: "scanemail",
                element: <ScanEmail />,
              },
              {
                path: "scanemail/newitem",
                element: <AddNewItem />,
              },
              {
                path: "uploadFile",
                element: <UploadFile />,
              },
            ],
          },
          {
            path: "styleme",
            element: <StyleMe />,
          },
          {
            path: "curateme",
            element: <CurateMe />,
          },
          {
            path: "favorites",
            element: <Favorites />,
          },
          {
            path: "/notifications",
            element: <Notifications />,
          },
          {
            path: "/profile",
            element: <Profile />,
          },
          {
            path: "/followme",
            element: <Profile />,
          },
          {
            path: "/followme/followers",
            element: <Followers />,
          },
          {
            path: "/followme/:username/followers",
            element: <Followers />,
          },
          {
            path: "/followme/following",
            element: <Followings />,
          },
          {
            path: "/followme/:username/followings",
            element: <Followings />,
          },
          {
            path: "/followme/creators",
            element: <Creators />,
          },
          {
            path: "/followme/follow_requests",
            element: <FollowRequests />,
          },

          {
            path: "/followme/profile/:username",
            element: <CreatorProfile />,
          },

          {
            path: "/settings",
            element: <Settings />,
            children: [
              {
                path: "account",
                element: <Account />,
              },
              {
                path: "notificationSetting",
                element: <NotificationSetting />,
              },
              {
                path: "scanemails",
                element: <ScanEmails />,
              },
              {
                path: "receipts",
                element: <Receipts />,
              },
              {
                path: "hiddenCategory",
                element: <HiddenCategory />,
              },
              {
                path: "*",
                element: <HiddenCategory />,
              },
            ],
          },
          {
            path: "*",
            element: <Dashboard />,
          },
        ],
      },
      {
        path: "/aboutus",
        element: <AboutUs />,
      },
      {
        path: "/contactus",
        element: <ContactUs path="/contactus" />,
      },
    ],
  },
]);

export default routes;
